import {
  Business,
  DeleteForeverOutlined,
  MoreVert,
  Person,
} from "@mui/icons-material";
import { Avatar, Button, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import classNames from "classnames";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyAccountStateChip from "../../../components/CompanyAccountStateChip/CompanyAccountStateChip";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import PlanSubscriptionChip from "../../../components/PlanSubscriptionChip/PlanSubscriptionChip";
import PurposeOfUseChip from "../../../components/PurposeOfUseChip/PurposeOfUseChip";
import useDeleteConfirm from "../../../hooks/use-delete-confirm";
import { companiesApi } from "../../../http";
import { Company, Pagination } from "../../../types";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import { isEmailVerified, toPurposeOfUse } from "../../../utils/coimex-utils";
import { appActions } from "../../app/app.state";
import { getCompaniesAction } from "../companies.effects";
import {
  selectCompanies,
  selectCompaniesOrderByFilter,
  selectCompaniesPagination,
  selectIsGetCompaniesPending,
} from "../companies.selectors";

export default function CompaniesTable() {
  const companies = useSelector(selectCompanies);
  const pagination = useSelector(selectCompaniesPagination);
  const isGetCompaniesPending = useSelector(selectIsGetCompaniesPending);
  const orderBy = useSelector(selectCompaniesOrderByFilter);
  const [rows, setRows] = useState([]);
  const [selectedMenuCompany, setSelectedMenuCompany] =
    useState<Company | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const confirmDelete = useDeleteConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    dispatch(getCompaniesAction(pg));
  };

  const handleSorting = (sort: GridSortModel) => {
    dispatch(
      getCompaniesAction({
        filters: { orderBy: sort?.[0] || { field: "createdAt", sort: "desc" } },
      })
    );
  };

  const handleRowClick = (company: Company) => {
    navigate(company.id);
  };

  const handleDeleteCompany = async () => {
    closeMenu();
    if (
      await confirmDelete({
        title: selectedMenuCompany!.name,
        onConfirm: () => companiesApi.deleteCompany(selectedMenuCompany!.id),
      })
    ) {
      enqueueSnackbar(
        `Company ${selectedMenuCompany?.name} deleted successfully.`,
        { variant: "info" }
      );
      dispatch(
        appActions.changeRefresh({
          page: AppPage.Companies,
          status: AppRefreshStatus.Invalidated,
        })
      );
    }
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    company: Company
  ) => {
    e.stopPropagation();
    setSelectedMenuCompany(company);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    setRows(
      (companies?.map((company, index) => ({
        ...company,
        rowNo: pagination.page * pagination.pageSize + index + 1,
        ownerFullName: company.owner.fullName || company.owner.email,
      })) as any) || []
    );
  }, [companies]);

  const cols = useMemo<GridColDef[]>(
    () => [
      {
        field: "rowNo",
        headerName: "",
        width: 50,
        sortable: false,
      },
      {
        field: "name",
        headerName: "Company",
        renderCell: (params) => (
          <div className="flex items-center gap-x-2">
            <Avatar src={params.row.avatarUrl} sx={{ width: 28, height: 28 }}>
              <Business fontSize="small" />
            </Avatar>
            <span>{params.value}</span>
          </div>
        ),
        flex: 1,
      },
      {
        field: "ownerFullName",
        headerName: "Owner",
        renderCell: (params) => {
          const isVerified = isEmailVerified(
            params.row.owner.accountStateOrder
          );
          return (
            <Tooltip title={isVerified ? "" : "Email Not Verfied"}>
              <div className="flex items-center gap-x-2">
                <Avatar
                  src={params.row.owner.avatarUrl}
                  sx={{ width: 28, height: 28 }}
                >
                  <Person fontSize="small" />
                </Avatar>
                <span
                  className={classNames({
                    "text-red-700": !isVerified,
                  })}
                >
                  {params.value}
                </span>
              </div>
            </Tooltip>
          );
        },
        flex: 1,
      },
      {
        field: "accountState",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => (
          <CompanyAccountStateChip
            status={params.value}
            remarks={params.row.remarks}
          />
        ),
      },
      {
        field: "purposeOfUse",
        headerName: "Type",
        renderCell: (params) =>
          params.value ? (
            <PurposeOfUseChip purposeOfUse={params.value} />
          ) : (
            <></>
          ),
        width: 120,
      },
      {
        field: "activePlan",
        headerName: "Plan",
        renderCell: (params) => (
          <div className="flex flex-col gap-0.5">
            {params.value && (
              <PlanSubscriptionChip
                type={params.value.type}
                durationType={params.value.duration?.type}
                purposeOfUse={toPurposeOfUse(params.value.roles)}
              />
            )}
          </div>
        ),
        width: 190,
      },
      {
        field: "createdAt",
        headerName: "Joined At",
        valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
        width: 120,
      },
      {
        field: "",
        renderCell: (params) => (
          <IconButton onClick={(e) => showMenu(e, params.row)}>
            <MoreVert />
          </IconButton>
        ),
        width: 50,
        align: "right",
        sortable: false,
      },
    ],
    []
  );

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
        loading={isGetCompaniesPending}
        pagination
        paginationMode="server"
        autoPageSize
        rowCount={pagination.total}
        page={pagination.page}
        sortingMode="server"
        sortModel={orderBy ? [orderBy] : []}
        onSortModelChange={handleSorting}
        onPageChange={(page) => handlePagination({ page })}
        onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No companies" />,
        }}
        getRowClassName={(params) => "cursor-pointer"}
        onRowClick={({ row }) => handleRowClick(row)}
      />
      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <Button
          color="error"
          variant="text"
          startIcon={<DeleteForeverOutlined />}
          onClick={handleDeleteCompany}
        >
          Delete
        </Button>
      </MoreMenu>
    </div>
  );
}
