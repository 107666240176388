import { Grow, Input, Paper, Popper } from "@mui/material";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import { useRef, useState } from "react";
import ReactDateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
const moment = extendMoment(originalMoment);

interface IProps {
  value?: { start: number; end: number };
  onChange?: (e: { start: number; end: number }) => void;
}

const stateDefinitions = {
  available: {
    color: null,
    label: "Available",
  },
  today: {
    color: "var(--color-primary)",
    label: "Today",
  },
};

export default function DateRangePicker({ value, onChange }: IProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const dateStates = [
    {
      state: "today",
      range: moment.range(moment(), moment()),
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <div className="flex items-center gap-2" ref={anchorRef}>
        <Input
          value={value?.start ? moment(value.start).format("YYYY-MM-DD") : ""}
          placeholder="Start Date"
          size="small"
          readOnly
          onClick={handleToggle}
        />
        to
        <Input
          value={value?.end ? moment(value.end).format("YYYY-MM-DD") : ""}
          placeholder="End Date"
          size="small"
          readOnly
          onClick={handleToggle}
        />
      </div>
      <Popper
        sx={{
          zIndex: 2001,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="pt-4 pb-2">
              <ReactDateRangePicker
                numberOfCalendars={2}
                selectionType="range"
                singleDateRange
                stateDefinitions={stateDefinitions}
                dateStates={dateStates}
                defaultState="available"
                value={
                  value
                    ? moment.range(moment(value.start), moment(value.end))
                    : null
                }
                onSelect={(e) => {
                  onChange({
                    start: moment(e.start).valueOf() as any,
                    end: moment(e.end).valueOf() as any,
                  });
                  setOpen(false);
                }}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
