import { yupResolver } from "@hookform/resolvers/yup";
import { PercentOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import EstimateGasFeeTooltip from "../../../components/EstimateGasFeeTooltip/EstimateGasFeeTooltip";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { Address, CommodityTokenConversionCommission } from "../../../types";
import { toBlockchainPercent } from "../../../utils/blockchain-utils";
import { getConfigsAction } from "../../settings/settings.effects";
import {
  selectCommodityExchangeTokens,
  selectCommodityTokenConversionCommissionToken,
} from "../../settings/settings.selectors";
import _ from "lodash";

interface IProps {
  close?: (modified?: boolean) => void;
}

export default function SetCommodityTokenConversionCommissionForm({
  close,
}: IProps) {
  const [
    commodityTokenConversionCommission,
    setCommodityTokenConversionCommission,
  ] = useState<CommodityTokenConversionCommission>();
  const [
    isGetCommodityTokenConversionCommissionPending,
    setIsGetCommodityTokenConversionCommissionPending,
  ] = useState(false);
  const conversionCommissionToken = useSelector(
    selectCommodityTokenConversionCommissionToken
  );
  const commodityExchangeTokens = useSelector(selectCommodityExchangeTokens);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasConversionCommissionGasFee, setHasConversionCommissionGasFee] =
    useState(false);
  const validationSchema = Yup.object().shape({
    token: Yup.string().required(),
    conversionCommission: Yup.number().required().min(0).max(100),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      token: "",
      conversionCommission: null,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const token = form.watch("token");
  const conversionCommission = +(
    form.watch("conversionCommission") || undefined
  );

  const getCommodityTokenConversionCommission = async (token: Address) => {
    try {
      setIsGetCommodityTokenConversionCommissionPending(true);
      setCommodityTokenConversionCommission(
        await commodityTokenApi.getConversionCommission(token)
      );
    } finally {
      setIsGetCommodityTokenConversionCommissionPending(false);
    }
  };

  const getHasConversionCommissionGasFee = async () => {
    setHasConversionCommissionGasFee(
      await commodityTokenApi.hasConversionCommissionGasFee(
        token,
        conversionCommission
      )
    );
  };

  const getEstimateGasFeeArgs = () => {
    const { token } = form.getValues();
    const { n, d } = toBlockchainPercent(conversionCommission);

    return [token, n, d];
  };

  const patchFormValue = (key, value) => {
    form.setValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = async ({ token, conversionCommission }) => {
    try {
      setIsSubmitting(true);
      await commodityTokenApi.setConversionCommission(
        token,
        conversionCommission
      );
      dispatch(getConfigsAction());
      close(true);
      enqueueSnackbar("Conversion commission was set successfully", {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    form.formState.isValid &&
      token &&
      conversionCommission &&
      getHasConversionCommissionGasFee();
  }, [token, conversionCommission, form.formState.isValid]);

  const renderChange = (oldCommission: number, newCommission: number) => {
    const change = newCommission - oldCommission;
    return (
      <div className="mt-2 ml-3.5 flex flex-col text-[13px] opacity-70">
        <div className="grid grid-cols-[120px_1fr]">
          Old commission:
          <span>{oldCommission}%</span>
        </div>

        <div className="grid grid-cols-[120px_1fr]">
          <span>Change:</span>
          <span>
            {change > 0 ? "+" : change < 0 ? "-" : ""}
            {isNaN(newCommission)
              ? ""
              : (newCommission - oldCommission).toFixed(3) + "%"}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    patchFormValue("token", conversionCommissionToken?.address || "");
  }, [conversionCommissionToken]);

  useEffect(() => {
    commodityTokenConversionCommission &&
      patchFormValue(
        "conversionCommission",
        commodityTokenConversionCommission?.conversionCommission || null
      );
  }, [commodityTokenConversionCommission]);

  useEffect(() => {
    token && getCommodityTokenConversionCommission(token);
  }, [token]);

  return (
    <form className="p-4 w-[300px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        Set Conversion Commission
      </header>

      <div className="flex flex-col gap-8">
        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Conversion Currency</InputLabel>
          <Select
            label="Conversion Currency"
            {...form.register("token")}
            value={token}
            error={!!form.formState.errors?.token}
          >
            {commodityExchangeTokens.map((t) => (
              <MenuItem key={t.address} value={t.address}>
                {t.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="relative">
          {isGetCommodityTokenConversionCommissionPending && <LinearProgress />}
          <TextField
            className="w-full"
            {...form.register("conversionCommission")}
            inputMode="decimal"
            label="Commission Rate"
            variant="filled"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: conversionCommission != null }}
            error={!!form.formState.errors?.conversionCommission}
          />

          {commodityTokenConversionCommission &&
            renderChange(
              commodityTokenConversionCommission.conversionCommission,
              conversionCommission
            )}
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <EstimateGasFeeTooltip
          method="setConversionCommission"
          args={getEstimateGasFeeArgs}
          disabled={!form.formState.isValid || !hasConversionCommissionGasFee}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </EstimateGasFeeTooltip>

        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
