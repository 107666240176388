import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { ReactNode, useState } from "react";
import { CloseModal } from "../../providers/ModalProvider";

export interface IYesNoConfirmProps {
  title: string;
  message: string;
  onYes: () => Promise<void> | void;
  opts?: {
    renderYes: ({ children }) => JSX.Element;
  };
  close: CloseModal<boolean>;
}

export default function YesNoConfirm({
  title,
  message,
  onYes,
  opts,
  close,
}: IYesNoConfirmProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const Yes = opts?.renderYes ?? (({ children }) => <>{children}</>);

  const handleYesClick = async () => {
    try {
      setIsSubmitting(true);
      await onYes();
      close(true);
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4 w-[400px]">
      <header className="modal-header">{title ?? "Confirm"}</header>
      <div>{message}</div>
      <footer className="modal-footer flex justify-end gap-4">
        <Button type="button" variant="text" onClick={() => close(false)}>
          No
        </Button>
        <Yes>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            onClick={handleYesClick}
          >
            Yes
          </LoadingButton>
        </Yes>
      </footer>
    </div>
  );
}
