import axios from "axios";
import tokenInterceptor from "./token.interceptor";
import unwrapDataInterceptor from "./unwrap-data.interceptor";
import { CONSTANTS } from "../common/constants";

export * from "./auth.api";
export * from "./companies.api";
export * from "./configs.api";
export * from "./payments.api";
export * from "./opportunities.api";
export * from "./me.api";
export * from './invest.api';
export * from "./treasury.api";

const api = axios.create({
  baseURL: `${CONSTANTS.CoreServiceUrl}/${CONSTANTS.ApiVersionPath}`,
  timeout: CONSTANTS.Api.timeout,
});

export default api;

unwrapDataInterceptor();
tokenInterceptor();
