import classNames from "classnames";

interface IProps {
  amount?: number;
  icon: string;
  className?: string;
}

export default function ContractCurrency({ amount, icon, className }: IProps) {
  return (
    <div className="flex items-center gap-1">
      <img className={classNames("w-6 h-6", className)} src={icon} />
      <span className="font-medium">{amount}</span>
    </div>
  );
}
