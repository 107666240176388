import api from ".";
import { AddNewTokenTx } from "../modules/commodity-tokens/types/add-new-token-tx.type";
import { AddTokenSupplyTx } from "../modules/commodity-tokens/types/add-token-supply-tx.type";
import { SetTokenCommissionTx } from "../modules/commodity-tokens/types/set-token-commission-tx.type";
import { SetTokenMinMaxAmountTx } from "../modules/commodity-tokens/types/set-token-min-max-amount-tx.type";
import { SetTokenPriceTx } from "../modules/commodity-tokens/types/set-token-price-tx.type";
import { ToggleTokenPauseTx } from "../modules/commodity-tokens/types/toggle-token-pause-tx.type";
import { Address, CommodityToken, CommodityTokenConversionCommission, Paginated, PaginationQuery } from "../types";

export const commodityTokenApi = {
  getCommodityTokens: async (): Promise<CommodityToken[]> => {
    return (await api.get("/commodity-tokens")).data;
  },
  async addNewToken(model): Promise<AddNewTokenTx> {
    return (await api.post("/commodity-tokens", model)).data;
  },
  addTokenSupply: (
    token: CommodityToken["address"],
    amount: number
  ): Promise<unknown> => {
    return api.patch(`/commodity-tokens/${token}/add-supply`, { amount });
  },
  setTokenPrice: (
    rwaToken: CommodityToken["address"],
    token: CommodityToken["address"],
    buyPrice: number,
    sellPrice: number
  ): Promise<unknown> => {
    return api.patch(`/commodity-tokens/${rwaToken}/set-price`, {
      token,
      buyPrice,
      sellPrice,
    });
  },
  setTokenCommission: (
    rwaToken: CommodityToken["address"],
    token: CommodityToken["address"],
    buyCommission: number,
    sellCommission: number
  ): Promise<unknown> => {
    return api.patch(`/commodity-tokens/${rwaToken}/set-commission`, {
      token,
      buyCommission,
      sellCommission,
    });
  },
  setTokenMinMaxAmount: (
    rwaToken: CommodityToken["address"],
    token: CommodityToken["address"],
    minAmount: number,
    maxAmount: number
  ): Promise<unknown> => {
    return api.patch(`/commodity-tokens/${rwaToken}/set-min-max-amount`, {
      token,
      minAmount,
      maxAmount,
    });
  },
  toggleTokenPause: (
    rwaToken: CommodityToken["address"],
    pause: boolean
  ): Promise<void> => {
    return api.patch(`/commodity-tokens/${rwaToken}/toggle-pause`, {
      pause,
    });
  },
  hasConversionCommissionGasFee: async (token: Address, conversionCommission): Promise<boolean> => {
    return (await api.get('/commodity-tokens/conversion-commission/has-gas-fee', {
      params: {
        token, conversionCommission
      }
    })).data;
  },
  setConversionCommission: (token: Address, conversionCommission): Promise<void> => {
    return api.patch('/commodity-tokens/conversion-commission', {
      token, conversionCommission
    });
  },
  getAddTokenSupplyTxs(
    rwaToken: CommodityToken["address"],
    params?: PaginationQuery
  ): Promise<Paginated<AddTokenSupplyTx>> {
    return api.get(`/commodity-tokens/${rwaToken}/add-total-supply-txs`, {
      params,
    });
  },
  getToggleTokenPauseTxs(
    rwaToken: CommodityToken["address"],
    params?: PaginationQuery
  ): Promise<Paginated<ToggleTokenPauseTx>> {
    return api.get(`/commodity-tokens/${rwaToken}/toggle-pause-txs`, {
      params,
    });
  },
  getSetPriceTxs(
    rwaToken: CommodityToken["address"],
    token: CommodityToken["address"],
    params?: PaginationQuery
  ): Promise<Paginated<SetTokenPriceTx>> {
    return api.get(`/commodity-tokens/${rwaToken}/set-price-txs/${token}`, {
      params,
    });
  },
  getSetCommissionTxs(
    rwaToken: CommodityToken["address"],
    token: CommodityToken["address"],
    params?: PaginationQuery
  ): Promise<Paginated<SetTokenCommissionTx>> {
    return api.get(
      `/commodity-tokens/${rwaToken}/set-commission-txs/${token}`,
      {
        params,
      }
    );
  },
  getSetMinMaxAmountTxs(
    rwaToken: CommodityToken["address"],
    token: CommodityToken["address"],
    params?: PaginationQuery
  ): Promise<Paginated<SetTokenMinMaxAmountTx>> {
    return api.get(
      `/commodity-tokens/${rwaToken}/set-min-max-amount-txs/${token}`,
      {
        params,
      }
    );
  },
  async getConversionCommission(token?: Address): Promise<CommodityTokenConversionCommission> {
    return (await api.get('/commodity-tokens/conversion-commission', {
      params: { token }
    })).data;
  }
};
