import { useSnackbar } from "notistack";
import { ComponentProps, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useNavigate } from "react-router";
import useRole from "../hooks/use-role";
import { selectMe } from "../modules/auth/auth.selectors";
import { Roles } from "../types";

type IProps = Pick<ComponentProps<typeof Route>, "element"> & {
  role: Roles;
};

export default function ProtectedRoute({ role, element }: IProps) {
  const { hasRole } = useRole();
  const me = useSelector(selectMe);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (me && !hasRole(role)) {
      enqueueSnackbar("You don't have permission to access this page", {
        variant: "error",
      });

      navigate("/", { replace: true });
    }
  }, [me]);

  return hasRole(role) ? (element as JSX.Element) : null;
}
