import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import useBottomSheet from "../../../hooks/use-bottom-sheet";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import CompanyPage from "../../companies/components/CompanyPage";
import {
  getPaymentsAction,
  getPaymentsStatusCountsAction,
} from "../payments.effects";
import PaymentsFilters from "./PaymentsFilters";
import PaymentsTable from "./PaymentsTable";
import { selectIsAuthenticated } from "../../auth/auth.selectors";

export default function Payments() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const setBottomSheet = useBottomSheet();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getPayments = () => {
    dispatch(getPaymentsStatusCountsAction());
    dispatch(getPaymentsAction({ page: 0 }));
  };

  const handlePaymentPageClose = () => {
    navigate(-1);
  };

  const showPaymentPage = (companyId) => {
    setBottomSheet({
      component: (
        <CompanyPage id={companyId} onClose={handlePaymentPageClose} />
      ),
      onClose: handlePaymentPageClose,
    });
  };

  useEffectOnce(() => {
    dispatch(getPaymentsStatusCountsAction());
  });

  useEffect(() => {
    const { id } = params;
    id && isAuthenticated ? showPaymentPage(id) : setBottomSheet(null);
  }, [params?.id]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Payments
    ) {
      getPayments();
    }
  }, [refreshStatus, refreshPage]);

  return (
    <div className="w-constraint">
      <PaymentsFilters />
      <PaymentsTable />
    </div>
  );
}
