import { yupResolver } from "@hookform/resolvers/yup";
import { InputLabel, Slider, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { investApi } from "../../../http";
import { InvestPool, InvestPoolType } from "../../../types";
import { SaveInvestPoolRequest } from "../../blockchain/types";
import { usePromptPayPoolForm } from "../providers/PromptPayPoolFormProvider";

interface FormProps {
  tenureInDays: number;
  investmentOpenAt: number;
  investmentCloseAt: number;
  stakingStartAt: number;
}

const validationSchema = Yup.object().shape({
  tenureInDays: Yup.number().positive().required(),
  investmentOpenAt: Yup.number().required().positive(),
  investmentCloseAt: Yup.number()
    .required()
    .positive()
    .test("afterInvestmentOpenAt", function (value) {
      const { investmentOpenAt } = this.parent;
      return value > investmentOpenAt;
    }),
  stakingStartAt: Yup.number()
    .required()
    .positive()
    .test("afterInvestmentCloseAt", function (value) {
      const { investmentCloseAt } = this.parent;
      return value >= investmentCloseAt;
    }),
});

const TENURES = [
  { label: "30", value: 30 },
  { label: "90", value: 90 },
  { label: "360", value: 360 },
];

export default function PromptPayPoolFormTenure({ children }) {
  const { value, setValue, next, setNexting, onBack } = usePromptPayPoolForm();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      tenureInDays: TENURES[0].value,
      investmentOpenAt: moment().valueOf(),
      investmentCloseAt: moment().valueOf(),
      stakingStartAt: moment().valueOf(),
    },
  };
  const {
    watch,
    reset,
    setValue: setFormValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions as any);

  const onSubmit = async (formValue: FormProps) => {
    try {
      setNexting(true);
      const { investToken, ...rest } = value.pool;
      const pool = await investApi.saveInvestPool({
        type: InvestPoolType.PromptPay,
        tokenAddress: investToken.address,
        ...rest,
        ...formValue,
      } as SaveInvestPoolRequest);
      next({ pool });
    } finally {
      setNexting(false);
    }
  };

  const patchFormValue = (key: keyof FormProps, value) => {
    setFormValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const fromDto = (pool: Partial<InvestPool>): FormProps => {
    return {
      tenureInDays: pool.tenureInDays || formOptions.defaultValues.tenureInDays,
      investmentOpenAt:
        pool.investmentOpenAt || formOptions.defaultValues.investmentOpenAt,
      investmentCloseAt:
        pool.investmentCloseAt || formOptions.defaultValues.investmentCloseAt,
      stakingStartAt:
        pool.stakingStartAt || formOptions.defaultValues.stakingStartAt,
    };
  };

  const handleBack = () => {
    setValue({ pool: { ...value.pool, ...getValues() } });
  };

  useEffect(() => {
    onBack?.addListener("back", handleBack);

    return () => {
      onBack?.removeListener("back", handleBack);
    };
  }, [onBack]);

  useEffect(() => {
    value?.pool && reset(fromDto(value.pool));
  }, [value?.pool]);

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <div className="mt-4 flex gap-4">
          <InputLabel className="basis-[100px]">Duration</InputLabel>
          <Slider
            className="mr-6"
            value={watch("tenureInDays")}
            onChange={(_, value) => patchFormValue("tenureInDays", value)}
            step={null}
            marks={TENURES}
            min={TENURES[0].value}
            max={TENURES[TENURES.length - 1].value}
            valueLabelDisplay="on"
            valueLabelFormat={(n) => `${n} Days`}
          />
        </div>

        <DateTimePicker
          label="Investment Open At"
          renderInput={(params) => <TextField {...params} />}
          value={watch("investmentOpenAt")}
          onChange={(e) =>
            patchFormValue("investmentOpenAt", moment(e).valueOf())
          }
          InputProps={{
            error: !!errors?.investmentOpenAt,
          }}
        />

        <DateTimePicker
          label="Investment Close At"
          renderInput={(params) => <TextField {...params} />}
          value={watch("investmentCloseAt")}
          onChange={(e) =>
            patchFormValue("investmentCloseAt", moment(e).valueOf())
          }
          InputProps={{
            error: !!errors?.investmentCloseAt,
          }}
        />

        <DateTimePicker
          label="Staking Start At"
          renderInput={(params) => <TextField {...params} />}
          value={watch("stakingStartAt")}
          onChange={(e) =>
            patchFormValue("stakingStartAt", moment(e).valueOf())
          }
          InputProps={{
            error: !!errors?.stakingStartAt,
          }}
        />
      </div>
      {children}
    </form>
  );
}
