import { createTheme, PaletteColorOptions } from "@mui/material";

const { palette } = createTheme({});
const theme = createTheme({
  palette: {
    primary: {
      main: "#8791a7",
    },
    secondary: {
      main: "#17a2b8",
    },
    white: palette.augmentColor({ color: { 500: "#ffffff" } }),
    black: palette.augmentColor({ color: { 500: "rgba(0, 0, 0, 0.87)" } }),
    greenLight: palette.augmentColor({ color: { 500: "#39DB80" } }),
    yellow: palette.augmentColor({ color: { 500: "#FCD900" } }),
    palePink: palette.augmentColor({ color: { 500: "#FAD9E6" } }),
    seaGreen: palette.augmentColor({ color: { 500: "#B9F8D3" } }),
    smoke: palette.augmentColor({ color: { 500: "#6d6875" } }),
    skyBlue: palette.augmentColor({ color: { 500: "#bde0fe" } }),
    slateBlue: palette.augmentColor({ color: { 500: "#6096ba" } }),
    purple: palette.augmentColor({ color: { 500: "#8367c7" } }),
    bezh: palette.augmentColor({ color: { 500: "#a6808c" } }),
    paleGreen: palette.augmentColor({ color: { 500: "#a2f5c6" } }),
    gray: palette.augmentColor({ color: { 500: "#e0e0e0" } }),
    darkGold: palette.augmentColor({ color: { 500: "#b08d57" } }),
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        placement: "top",
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: '#38466d'
        },
        arrow: {
          color: '#38466d'
        }
      }
    },
  },
});

export default theme;

declare module "@mui/material/styles" {
  interface Palette {
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    yellow: PaletteColorOptions;
    palePink: PaletteColorOptions;
    seaGreen: PaletteColorOptions;
    smoke: PaletteColorOptions;
    skyBlue: PaletteColorOptions;
    slateBlue: PaletteColorOptions;
    purple: PaletteColorOptions;
    greenLight: PaletteColorOptions;
    bezh: PaletteColorOptions;
    paleGreen: PaletteColorOptions;
    gray: PaletteColorOptions;
    darkGold: PaletteColorOptions;
  }
  interface PaletteOptions {
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    yellow: PaletteColorOptions;
    palePink: PaletteColorOptions;
    seaGreen: PaletteColorOptions;
    smoke: PaletteColorOptions;
    skyBlue: PaletteColorOptions;
    slateBlue: PaletteColorOptions;
    purple: PaletteColorOptions;
    greenLight: PaletteColorOptions;
    bezh: PaletteColorOptions;
    paleGreen: PaletteColorOptions;
    gray: PaletteColorOptions;
    darkGold: PaletteColorOptions;
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
    yellow: true;
    palePink: true;
    seaGreen: true;
    smoke: true;
    skyBlue: true;
    slateBlue: true;
    purple: true;
    greenLight: true;
    bezh: true;
    paleGreen: true;
    gray: true;
    darkGold: true;
  }
  interface ChipPropsColorOverrides {
    white: true;
    black: true;
    yellow: true;
    palePink: true;
    seaGreen: true;
    smoke: true;
    skyBlue: true;
    slateBlue: true;
    purple: true;
    greenLight: true;
    bezh: true;
    paleGreen: true;
    gray: true;
    darkGold: true;
  }

  interface IconButtonPropsColorOverrides {
    white: true;
    black: true;
    yellow: true;
    palePink: true;
    seaGreen: true;
    smoke: true;
    skyBlue: true;
    slateBlue: true;
    purple: true;
    greenLight: true;
    bezh: true;
    paleGreen: true;
    gray: true;
    darkGold: true;
  }

  interface CircularProgressPropsColorOverrides {
    white: true;
  }

  interface LinearProgressPropsColorOverrides {
    white: true;
    black: true;
    yellow: true;
    palePink: true;
    seaGreen: true;
    smoke: true;
    skyBlue: true;
    slateBlue: true;
    purple: true;
    greenLight: true;
    bezh: true;
    paleGreen: true;
    gray: true;
    darkGold: true;
  }

  interface ButtonGroupPropsColorOverrides {
    white: true;
    black: true;
    yellow: true;
    palePink: true;
    seaGreen: true;
    smoke: true;
    skyBlue: true;
    slateBlue: true;
    purple: true;
    greenLight: true;
    bezh: true;
    paleGreen: true;
    gray: true;
    darkGold: true;
  }
}
