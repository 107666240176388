import {
  BlockchainTxType,
  BlockchainTxStatus,
} from "../modules/blockchain/types";

export enum CommodityTokenTxAction {
  Buy = "Buy",
  Sell = "Sell",
  Convert = "Convert",
}

export class CommodityTokenTx {
  txHash: string;
  txUrl: string;
  type: BlockchainTxType;
  status: BlockchainTxStatus;
  account: string;
  action: CommodityTokenTxAction;
  rwaAddress: string;
  tokenAddress: string;
  rwaSymbol: string;
  tokenSymbol: string;
  amount: number;
  unitPrice: number;
  totalPrice: number;
  blockTimestamp: number;
  updatedAt: number;
  createdAt: number;
}

export const CommodityTokenTxActions = Object.keys(CommodityTokenTxAction);
