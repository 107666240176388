import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import Tabs from "../../../components/Tabs/Tabs";
import { TreasuryTransactionCategoryText } from "../../../utils/coimex-utils";
import {
  selectedTreasuryTransactionsSelectedCategory,
  selectIsGetTreasuryTransactionsPending,
} from "../treasury.selectors";
import { treasuryActions } from "../treasury.state";
import {
  toTreasuryTransactionCategoryIndex,
  TreasuryTransactionCategories,
  TreasuryTransactionCategory,
} from "../types/treasury-transactions-category.type";
import TreasuryInternalTransactions from "./TreasuryInternalTransactions";
import TreasuryInvestPoolTransactions from "./TreasuryInvestPoolTransactions";

export default function TreasuryTransactions() {
  const isGetTransactionsPending = useSelector(
    selectIsGetTreasuryTransactionsPending
  );
  const selectedCategory = useSelector(
    selectedTreasuryTransactionsSelectedCategory
  );
  const dispatch = useDispatch();

  const renderTabContent = () => {
    switch (selectedCategory) {
      case TreasuryTransactionCategory.Internal:
        return <TreasuryInternalTransactions />;

      case TreasuryTransactionCategory.InvestPool:
        return <TreasuryInvestPoolTransactions />;
    }
  };

  return (
    <LoadingOverlay loading={isGetTransactionsPending}>
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center justify-between">
          <Tabs
            selectedTab={toTreasuryTransactionCategoryIndex(selectedCategory)}
            items={TreasuryTransactionCategories}
            renderItem={(item) => (
              <span>{TreasuryTransactionCategoryText[item]}</span>
            )}
            onTabChange={(tab) =>
              dispatch(
                treasuryActions.changeSelectedTransactionsCategory(
                  TreasuryTransactionCategories[tab]
                )
              )
            }
          />
        </div>
        <div className="flex-1">{renderTabContent()}</div>
      </div>
    </LoadingOverlay>
  );
}
