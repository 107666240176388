import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import ModalProvider from "../../../providers/ModalProvider";
import WalletConnectorProvider from "../../../providers/WalletConnectorProvider";
import { AppPage, AppRefreshStatus } from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import { appActions } from "../../app/app.state";
import TokenTransferModalProvider from "../providers/TokenTransferModalProvider";
import {
  getAccountBalancesActionsAction,
  getRwaContractBalancesAction,
  getTreasuryTransactionsAction,
} from "../treasury.effects";
import {
  selectIsGetAccountBalancesPending,
  selectIsGetRwaContractBalancesPending,
  selectIsGetTreasuryTransactionsPending,
} from "../treasury.selectors";
import AccountBalances from "./AccountBalances";
import RwaContractBalances from "./RwaContractBalances";
import "./Treasury.scss";
import TreasuryTransactions from "./TreasuryTransactions";

export default function Treasury() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const isGetAccountBalancesPending = useSelector(
    selectIsGetAccountBalancesPending
  );
  const isGetRwaContractBalancesPending = useSelector(
    selectIsGetRwaContractBalancesPending
  );
  const isGetTreasuryTransactionsPending = useSelector(
    selectIsGetTreasuryTransactionsPending
  );
  const dispatch = useDispatch();
  const firstRender = useRef(true);

  const getTreasuryData = () => {
    dispatch(getRwaContractBalancesAction());
    dispatch(getAccountBalancesActionsAction());
    !firstRender.current && dispatch(getTreasuryTransactionsAction({}));
  };

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Treasury
    ) {
      getTreasuryData();
    }
  }, [refreshStatus, refreshPage]);

  useEffect(() => {
    let status = AppRefreshStatus.Idle;
    if (
      isGetRwaContractBalancesPending ||
      isGetAccountBalancesPending ||
      isGetTreasuryTransactionsPending
    ) {
      status = AppRefreshStatus.InProgress;
    }
    if (refreshStatus !== status) {
      dispatch(
        appActions.changeRefresh({ page: AppPage.Treasury, status: status })
      );
    }
  }, [
    isGetRwaContractBalancesPending,
    isGetAccountBalancesPending,
    isGetTreasuryTransactionsPending,
    refreshStatus,
  ]);

  useEffectOnce(() => {
    getTreasuryData();
    firstRender.current = false;
  });

  return (
    <WalletConnectorProvider>
      <ModalProvider>
        <TokenTransferModalProvider>
          <div className="TreasuryManagement">
            <div className="Widget lg:col-span-2">
              <TreasuryTransactions />
            </div>

            <div className="Widget">
              <RwaContractBalances />
            </div>

            <div className="Widget">
              <AccountBalances />
            </div>
          </div>
        </TokenTransferModalProvider>
      </ModalProvider>
    </WalletConnectorProvider>
  );
}
