import { JsonRpcProvider, Provider } from "ethers";
import { createContext, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";

interface ContextProps {
  provider: Provider;
}

const BlockchainContext = createContext<ContextProps>(null);

export default function BlockchainProvider({ children }) {
  const blockchainConfigs = useSelector(selectBlockchainConfigs);
  const provider = useMemo(
    () =>
      blockchainConfigs?.providerUrlRpc
        ? new JsonRpcProvider(blockchainConfigs.providerUrlRpc)
        : null,
    [blockchainConfigs?.providerUrlRpc]
  );

  return (
    <BlockchainContext.Provider value={{ provider }}>
      {children}
    </BlockchainContext.Provider>
  );
}

export const useBlockchain = () => useContext(BlockchainContext);
