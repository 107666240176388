import { yupResolver } from "@hookform/resolvers/yup";
import { PercentOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import useFocus from "../../../hooks/use-focus";
import { InvestPool } from "../../../types";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { usePromptPayPoolForm } from "../providers/PromptPayPoolFormProvider";
import { selectCommodityTokens } from "../../commodity-tokens/commodity-tokens.selectors";

interface FormProps {
  id?: string;
  name: string;
  tokenAddress: string;
  depositAddress: string;
  interestRatePercentage: number;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  tokenAddress: Yup.string().required(),
  depositAddress: Yup.string()
    .required()
    .test("valid", function (value) {
      return isValidBlockchainAddress(value);
    }),
  interestRatePercentage: Yup.number().required().positive(),
});

export default function PromptPayPoolFormInfo({ children }) {
  const { value, next, setNexting } = usePromptPayPoolForm();
  const { nonRwaAssetTokens } = useSelector(selectBlockchainConfigs);
  const commodityTokens = useSelector(selectCommodityTokens);
  const investTokens = useMemo(
    () =>
      [...nonRwaAssetTokens, ...commodityTokens].filter(
        (t) => t.symbol != "USDT"
      ),
    /* TODO uncomment it when also invest option is enable
    .filter((t) => t.invest == "Enabled")*/
    [nonRwaAssetTokens, commodityTokens]
  );
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      tokenAddress: "",
      depositAddress: "",
      interestRatePercentage: "",
    },
  };
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions as any);
  const nameRef = useRef();
  useFocus(nameRef);

  const onSubmit = async ({
    id,
    name,
    tokenAddress,
    depositAddress,
    interestRatePercentage,
  }: FormProps) => {
    try {
      setNexting(true);
      const investToken = investTokens.find(
        (t) => t.address == tokenAddress
      ) as any;

      next({
        pool: {
          ...(value.pool || {}),
          id,
          name,
          investToken,
          depositAddress,
          interestRatePercentage,
        },
      });
    } finally {
      setNexting(false);
    }
  };

  const fromDto = (pool: Partial<InvestPool>): FormProps => {
    return {
      id: pool.id,
      name: pool.name,
      tokenAddress: pool.investToken.address,
      depositAddress: pool.depositAddress,
      interestRatePercentage: pool.interestRatePercentage,
    };
  };

  useEffect(() => {
    value?.pool && reset(fromDto(value.pool));
  }, [value?.pool, investTokens]);

  return (
    <form
      className="flex-1 w-full flex flex-col gap-4 mt-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 flex flex-col gap-6 px-1">
        <TextField
          inputRef={nameRef}
          className="w-full"
          {...register("name")}
          label="Pool Name"
          variant="filled"
          size="small"
          error={!!errors?.name}
        />

        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Invest Token</InputLabel>
          <Controller
            name="tokenAddress"
            control={control}
            render={({ field }) => (
              <Select
                label="Token"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!errors.tokenAddress}
              >
                {investTokens?.map((t) => (
                  <MenuItem key={t.symbol} value={t.address}>
                    {t.symbol}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <TextField
          className="w-full"
          {...register("depositAddress")}
          label="Deposit Address"
          variant="filled"
          size="small"
          error={!!errors?.depositAddress}
        />

        <TextField
          className="w-full"
          {...register("interestRatePercentage")}
          label="Minimum Return Rate"
          inputMode="numeric"
          variant="filled"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PercentOutlined />
              </InputAdornment>
            ),
          }}
          error={!!errors?.interestRatePercentage}
        />
      </div>
      {children}
    </form>
  );
}
