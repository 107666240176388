import { createSlice } from "@reduxjs/toolkit";
import {
  CommodityTokenTx,
  InvestPoolTx,
  Paginated,
  TreasuryAccountBalance,
} from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import {
  getAccountBalancesActionsAction,
  getRwaContractBalancesAction,
  getTreasuryTransactionsAction,
} from "./treasury.effects";
import { RwaContractBalances } from "./types/rwa-contract-balances.type";
import { TreasuryTransactionCategory } from "./types/treasury-transactions-category.type";

export const TREASURY_STATE_NAME = "treasury";

export interface TreasuryState {
  accountBalances: TreasuryAccountBalance[];
  rwaContractBalances: RwaContractBalances;
  transactions: {
    category: TreasuryTransactionCategory;
    payload: Paginated<CommodityTokenTx | InvestPoolTx>;
  };
  selectedTransactionsCategory: TreasuryTransactionCategory;
  transactionsCategoriesPageSizes: Record<TreasuryTransactionCategory, number>;
}

export const treasuryState = createSlice({
  name: TREASURY_STATE_NAME,
  initialState: {
    accountBalances: [],
    selectedTransactionsCategory: TreasuryTransactionCategory.Internal,
    transactionsCategoriesPageSizes: {
      Internal: 7,
      InvestPool: 7,
      Dex: 7,
    },
  } as TreasuryState,
  reducers: {
    changeSelectedTransactionsCategory(
      state,
      { payload }: { payload: TreasuryTransactionCategory }
    ) {
      state.selectedTransactionsCategory = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountBalancesActionsAction.fulfilled, (state, action) => {
        state.accountBalances = action.payload;
      })
      .addCase(getRwaContractBalancesAction.fulfilled, (state, action) => {
        state.rwaContractBalances = action.payload;
      })
      .addCase(getTreasuryTransactionsAction.fulfilled, (state, action) => {
        state.transactions = action.payload;
      });
    addCommonReducers(builder, TREASURY_STATE_NAME);
  },
});

export const treasuryActions = treasuryState.actions;
export const treasuryReducer = treasuryState.reducer;
