import Button from "@mui/material/Button";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ReactNode, useState, useRef, ComponentProps } from "react";
import { CircularProgress } from "@mui/material";

interface IProps {
  options: ReactNode[];
  color?: ButtonGroupProps["color"];
  loading?: boolean;
  disabled?: boolean;
  onClick: (idx: number) => void;
}

export default function SplitButton({
  options,
  color = "secondary",
  loading,
  disabled,
  onClick,
}: IProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = (idx: number) => {
    setOpen(false);
    onClick(idx);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color={color}
        ref={anchorRef}
        disabled={disabled}
      >
        <Button className="normal-case" onClick={() => handleClick(0)}>
          {options[0]}
        </Button>
        <Button size="small" onClick={handleToggle}>
          {loading ? (
            <div className="w-6 flex justify-center items-center">
              <CircularProgress color="white" size={12} />
            </div>
          ) : (
            <ArrowDropDownIcon />
          )}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.slice(1).map((option, idx) => (
                    <MenuItem key={idx} onClick={() => handleClick(idx + 1)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
