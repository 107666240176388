import { KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, MobileStepper } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import EstimateGasFeeTooltip from "../../../../components/EstimateGasFeeTooltip/EstimateGasFeeTooltip";
import { toDecimalsAmount } from "../../../../utils/blockchain-utils";
import {
  selectBlockchainFeeToken,
  selectBlockchainPaymentToken,
} from "../../../settings/settings.selectors";
import { useSmartDealForm } from "../../providers/SmartDealFormProvider";
import SmartDealFormInfo from "./SmartDealFormInfo";
import SmartDealFormProformaInvoice from "./SmartDealFormProformaInvoice";
import SmartDealFormPublish from "./SmartDealFormPublish";

export const STEPS = 3;

export default function SmartDealForm() {
  const { value, step, nexting, close } = useSmartDealForm();
  const paymentToken = useSelector(selectBlockchainPaymentToken);
  const feeToken = useSelector(selectBlockchainFeeToken);

  const stepContent: { Component; title } = useMemo(() => {
    switch (step) {
      case 0:
        return {
          Component: SmartDealFormInfo,
          title: "Add Smart Deal",
        };

      case 1:
        return {
          Component: SmartDealFormProformaInvoice,
          title: "Smart Deal Proforma Invoice",
        };

      case 2:
        return {
          Component: SmartDealFormPublish,
          title: "Publish Smart Deal",
        };
    }
  }, [step]);

  const handleClose = () => {
    close(value?.smartDeal);
  };

  const getEstimateGasFeeArgs = () => {
    return [
      value.smartDeal.importerCompany.id,
      value.smartDeal.exporterCompany.id,
      toDecimalsAmount(value.proformaInvoice.deposit, paymentToken.decimals),
      toDecimalsAmount(value.proformaInvoice.balance, paymentToken.decimals),
      paymentToken.address,
      toDecimalsAmount(value.proformaInvoice.fixedFee, feeToken.decimals),
      toDecimalsAmount(value.proformaInvoice.commissionFee, feeToken.decimals),
      feeToken.address,
    ];
  };

  return (
    <div
      className="w-96 mt-2 p-4 flex flex-col gap-2"
      style={{ minHeight: "200px" }}
    >
      <div className="border-b border-border pb-2 mb-2">
        {stepContent.title}
      </div>
      <stepContent.Component>
        <MobileStepper
          variant="progress"
          color="secondary"
          steps={STEPS}
          position="static"
          activeStep={step}
          nextButton={
            <EstimateGasFeeTooltip
              method="createDeal"
              args={getEstimateGasFeeArgs}
              disabled={step != 2 || !value?.publishToBlockchain}
            >
              <LoadingButton
                size="small"
                type="submit"
                loading={nexting}
                tabIndex={0}
              >
                {step < STEPS - 1 ? "Next" : "Save"}
                {step < STEPS - 1 && <KeyboardArrowRight />}
              </LoadingButton>
            </EstimateGasFeeTooltip>
          }
          backButton={
            <Button size="small" onClick={handleClose} tabIndex={1}>
              close
            </Button>
          }
        />
      </stepContent.Component>
    </div>
  );
}
