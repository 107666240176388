import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useFocus from "../../../hooks/use-focus";
import { schedulesApi } from "../../../http/schedules.api";
import { CloseModal } from "../../../providers/ModalProvider";
import { Schedule } from "../../../types";
import Yup from "../../../types/yup-extended.type";
import {
  getCronExpressionText,
  ScheduleNameText,
} from "../../../utils/coimex-utils";
import { useSelector } from "react-redux";
import { selectSchedulerCronTz } from "../../settings/settings.selectors";

interface IProps {
  schedule: Schedule;
  close: CloseModal<boolean>;
}

const validationSchema = Yup.object().shape({
  croneExpression: Yup.string()
    .required("This field is required.")
    .croneExpression(),
});

export default function EditSchedule({ schedule, close }: IProps) {
  const cronTz = useSelector(selectSchedulerCronTz);
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const { enqueueSnackbar } = useSnackbar();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<{ croneExpression: string }>(formOptions as any);
  const croneExpression = watch("croneExpression");

  const onSubmit = async ({ croneExpression }) => {
    try {
      setIsSaving(true);
      await schedulesApi.updateSchedule(schedule.id, { croneExpression });
      close(true);
      enqueueSnackbar(
        `${ScheduleNameText[schedule?.name]} updated successfully`,
        { variant: "info" }
      );
    } catch {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    reset({ croneExpression: schedule?.croneExpression });
  }, [schedule]);

  return (
    <form
      className="max-w-full p-4"
      style={{ width: "400px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="modal-header text-xl pt-1.5 pb-3">
        {ScheduleNameText[schedule?.name]}
      </header>

      <div className="px-2">
        <div className="mb-5 flex flex-col gap-1">
          Timezone <span className="ml-2 text-text-1 text-sm">{cronTz}</span>
        </div>

        <label className="block -mb-1.5">Runs at</label>
        <TextField
          inputRef={inputRef}
          className="w-full mt-3"
          {...register("croneExpression")}
          placeholder="* * * * * *"
          error={!!errors?.croneExpression}
          variant="outlined"
          size="small"
          helperText={
            errors.croneExpression?.message ??
            getCronExpressionText(croneExpression)
          }
          inputProps={{
            autoComplete: "off",
          }}
        />
      </div>

      <footer className="modal-footer flex justify-end gap-4">
        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={isSaving}
        >
          Save
        </LoadingButton>
      </footer>
    </form>
  );
}
