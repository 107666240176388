import { Route, Routes } from "react-router";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import AuthLayout from "../layouts/AuthLayout/AuthLayout";
import MainLayout from "../layouts/MainLayout/MainLayout";
import LoginPage from "../modules/auth/components/LoginPage";
import ResetPasswordPage from "../modules/auth/components/ResetPasswordPage";
import TwoFactorPage from "../modules/auth/components/TwoFactorPage";
import SmartDeals from "../modules/blockchain/components/SmartDeals";
import CommodityTokens from "../modules/commodity-tokens/components/CommodityTokens";
import Companies from "../modules/companies/components/Companies";
import Dashboard from "../modules/dashboard/components/Dashboard";
import InvestPools from "../modules/invest/components/InvestPools";
import Offers from "../modules/offers/components/Offers";
import Payments from "../modules/payments/components/Payments";
import Plans from "../modules/plans/components/Plans";
import Requests from "../modules/requests/components/Requests";
import Schedules from "../modules/schedules/components/Schedules";
import Settings from "../modules/settings/components/Settings";
import Treasury from "../modules/treasury/components/Treasury";
import ProtectedRoute from "./ProtectedRoute";
import { Roles } from "../types";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="companies">
          <Route index element={<Companies />} />
          <Route path=":id" element={<Companies />} />
        </Route>
        <Route path="payments" element={<Payments />} />
        <Route path="offers">
          <Route index element={<Offers />} />
          <Route path=":id" element={<Offers />} />
        </Route>
        <Route path="requests">
          <Route index element={<Requests />} />
          <Route path=":id" element={<Requests />} />
        </Route>
        <Route path="plans">
          <Route index element={<Plans />} />
        </Route>
        <Route path="smart-deals">
          <Route
            index
            element={
              <ProtectedRoute
                element={<SmartDeals />}
                role={Roles.BlockchainAdmin}
              />
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute
                element={<SmartDeals />}
                role={Roles.BlockchainAdmin}
              />
            }
          />
        </Route>
        <Route path="commodity-tokens">
          <Route
            index
            element={
              <ProtectedRoute
                element={<CommodityTokens />}
                role={Roles.BlockchainAdmin}
              />
            }
          />
        </Route>
        <Route path="investment-pools">
          <Route
            index
            element={
              <ProtectedRoute
                element={<InvestPools />}
                role={Roles.BlockchainAdmin}
              />
            }
          />
          <Route
            path=":tab"
            element={
              <ProtectedRoute
                element={<InvestPools />}
                role={Roles.BlockchainAdmin}
              />
            }
          >
            <Route
              path=":id"
              element={
                <ProtectedRoute
                  element={<InvestPools />}
                  role={Roles.BlockchainAdmin}
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="treasury-management"
          element={
            <ProtectedRoute element={<Treasury />} role={Roles.TreasuryAdmin} />
          }
        />
        <Route path="schedules" element={<Schedules />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="2fa" element={<TwoFactorPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>
    </Routes>
  );
}
