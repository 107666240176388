import { Timeline as MuiTimeline } from "@mui/lab";
import { ComponentProps } from "react";

export default function Timeline(
  props: Partial<ComponentProps<typeof MuiTimeline>>
) {
  return (
    <MuiTimeline
      {...props}
      placeholder=""
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
    >
      {props.children}
    </MuiTimeline>
  );
}
