import { CreditCardOff } from "@mui/icons-material";
import { ReactNode, useMemo } from "react";
import { Chain } from "viem";
import { Connector } from "wagmi";
import { DisconnectMutate } from "wagmi/query";
import { WalletConnectorId, WalletConnectors } from "../../common/wagmi-config";
import { useModal } from "../../providers/ModalProvider";
import { toShortHash } from "../../utils/blockchain-utils";
import SplitButton from "../SplitButton/SplitButton";
import ConnectedWalletInfo from "./ConnectedWalletInfo";

interface IProps {
  isConnected: boolean;
  isConnecting: boolean;
  isReconnecting: boolean;
  address: string;
  connectors: readonly Connector[];
  connector: Connector;
  chain: Chain;
  disconnect: DisconnectMutate;
}

export default function WalletConnector({
  isConnected,
  isConnecting,
  isReconnecting,
  address,
  connectors,
  connector,
  chain,
  disconnect,
}: IProps) {
  const { openModal } = useModal();

  const showConnectedWalletInfo = () => {
    openModal(
      (props) => (
        <ConnectedWalletInfo
          {...props}
          address={address}
          connector={connector}
          chain={chain}
        />
      ),
      { hideCloseButton: false, closeOnClickOutside: true }
    );
  };

  const handleClick = (idx: number) => {
    if (isConnected) {
      if (idx == 0) {
        showConnectedWalletInfo();
      } else {
        disconnect();
      }
    } else {
      connectors[idx].connect();
    }
  };

  const options = useMemo<ReactNode[]>(
    () =>
      isConnected
        ? [
            <div className="flex items-center gap-2.5">
              <span className="rounded-full w-2 h-2 bg-green-300" />
              {toShortHash(address)}
            </div>,

            <div className="flex items-center gap-4">
              Disconnect Wallet <CreditCardOff fontSize="small" />
            </div>,
          ]
        : [
            <div className="flex items-center gap-2">
              Connect {WalletConnectors[WalletConnectorId.Metamask].name}
              <img
                className="w-4 h-4"
                src={WalletConnectors[WalletConnectorId.Metamask].icon}
              />
            </div>,

            <div className="flex items-center gap-2">
              Connect {WalletConnectors[WalletConnectorId.WalletConnect].name}
              <img
                className="w-6 h-6"
                src={WalletConnectors[WalletConnectorId.WalletConnect].icon}
              />
            </div>,
          ],
    [isConnected, connectors]
  );

  return (
    <SplitButton
      options={options}
      color={isConnected ? "secondary" : "gray"}
      loading={isConnecting}
      disabled={isConnecting || isReconnecting}
      onClick={handleClick}
    />
  );
}
