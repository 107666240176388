import { createAsyncThunk } from "@reduxjs/toolkit";
import { treasuryApi } from "../../http";
import { isNullOrEmpty, removeNullOrEmpty } from "../../utils/obj-utils";
import { RootState } from "../store";

export const getAccountBalancesActionsAction = createAsyncThunk(
  "treasury/getAccountBalances",
  async () => {
    return treasuryApi.getAccountBalances();
  }
);

export const getRwaContractBalancesAction = createAsyncThunk(
  "treasury/getRwaContractBalances",
  async () => {
    return treasuryApi.getRwaContractBalances();
  }
);

export const getTreasuryTransactionsAction = createAsyncThunk(
  "treasury/getTreasuryTxs",
  async (
    {
      page,
      filters,
    }: {
      page?: number;
      filters?: any;
    },
    thunk
  ) => {
    const category = (<RootState>thunk.getState()).treasury
      .selectedTransactionsCategory;
    const pageSize = (<RootState>thunk.getState()).treasury
      .transactionsCategoriesPageSizes[category];
    const payload = await treasuryApi.getTreasuryTxs(category, {
      from: page && pageSize ? page * pageSize : 0,
      take: pageSize,
      filters: isNullOrEmpty(filters)
        ? undefined
        : JSON.stringify(removeNullOrEmpty(filters)),
    });

    return { category, payload };
  }
);
