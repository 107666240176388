import { Business } from "@mui/icons-material";
import { Avatar, LinearProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import FileThumbnail from "../../../components/FileThumbnail/FileThumbnail";
import SmartDealDocumentStatusIndicator from "../../../components/SmartDealDocumentStatusIndicator/SmartDealDocumentStatusIndicator";
import { blockchainApi } from "../../../http/blockchain.api";
import { CloseModal } from "../../../providers/ModalProvider";
import { useWs } from "../../../providers/WsProvider";
import { BlockchainNotificationType } from "../../../types";
import { fromNow } from "../../../utils/dt-utils";
import { humanFileSize } from "../../../utils/file-util";
import {
  BlockchainEventType,
  BlockchainTxUpdatedNotification,
  SmartDeal,
  SmartDealDocumentApproveTx as SmartDealDocumentApproveTxType,
  SmartDealDocument as SmartDealDocumentType,
} from "../types";
import BlockchainFlowItem from "./BlockchainFlowItem";
import BlockchainFlowTx from "./BlockchainFlowTx";
import "./SmartDeals.scss";
import { getBlockChainEventData } from "../../../utils/coimex-utils";
import Timeline from "../../../components/Timeline/Timeline";

interface IProps {
  initialDocument: SmartDealDocumentType;
  close: CloseModal;
}

export default function SmartDealDocument({ initialDocument, close }: IProps) {
  const [document, setDocument] =
    useState<SmartDealDocumentType>(initialDocument);
  const [isGetDocumentPending, setIsGetDocumentPending] = useState(false);
  const [approveTxs, setApproveDocTxs] = useState<
    SmartDealDocumentApproveTxType[]
  >([]);
  const [isGetApproveTxsPending, setIsGetApproveTxsPending] = useState(false);
  const navigate = useNavigate();
  const { onBlockchainEvent } = useWs();
  const [invalidateCreateDocTxObj, setInvalidateCreateDocTxObj] =
    useState<object>();
  const debouncedInvalidateCreateDocTx = useDebounce(
    invalidateCreateDocTxObj,
    1000
  );
  const [invalidateApproveTxsObj, setInvalidateApproveTxsObj] =
    useState<object>();
  const debouncedInvalidateApproveTxs = useDebounce(
    invalidateApproveTxsObj,
    1000
  );
  const [_, docHash] = useMemo(
    () =>
      getBlockChainEventData(
        document?.createDocTx,
        BlockchainEventType.DocCreated
      ),
    [document?.createDocTx]
  );

  const getDocument = async () => {
    try {
      setIsGetDocumentPending(true);
      setDocument(await blockchainApi.getSmartDealDocument(initialDocument.id));
    } finally {
      setIsGetDocumentPending(false);
    }
  };

  const getApproveTxs = async () => {
    try {
      setIsGetApproveTxsPending(true);
      setApproveDocTxs(
        await blockchainApi.getSmartDealDocumentApproveTxs(initialDocument.id)
      );
    } finally {
      setIsGetApproveTxsPending(false);
    }
  };

  const navigateToCompany = (companyId: string) => {
    if (companyId) {
      close(true);
      navigate(`companies/${companyId}`);
    }
  };

  const handleBLockchainTxUpdatedNotification = (
    notification: BlockchainTxUpdatedNotification
  ) => {
    if (document?.createDocTx?.txHash == notification.txHash) {
      setInvalidateCreateDocTxObj({});
    }
    if (approveTxs?.some(({ tx }) => tx.txHash == notification.txHash)) {
      setInvalidateApproveTxsObj({});
    }
  };

  const handleBlockchainDocPublishedNotification = (
    notification: SmartDealDocumentType
  ) => {
    if (document?.id == notification.id) {
      setInvalidateCreateDocTxObj({});
    }
  };

  const handleBlockchainDocApprovedNotification = (notification: SmartDeal) => {
    if (document?.smartDealId == notification.id) {
      setInvalidateApproveTxsObj({});
      setInvalidateCreateDocTxObj({});
    }
  };

  useEffect(() => {
    if (debouncedInvalidateCreateDocTx) {
      getDocument();
    }
  }, [debouncedInvalidateCreateDocTx]);

  useEffect(() => {
    if (debouncedInvalidateApproveTxs) {
      getApproveTxs();
    }
  }, [debouncedInvalidateApproveTxs]);

  useEffect(() => {
    onBlockchainEvent?.on(
      BlockchainNotificationType.TxUpdated,
      handleBLockchainTxUpdatedNotification
    );

    onBlockchainEvent?.on(
      BlockchainNotificationType.DocPublished,
      handleBlockchainDocPublishedNotification
    );

    onBlockchainEvent?.on(
      BlockchainNotificationType.DocApproved,
      handleBlockchainDocApprovedNotification
    );

    return () => {
      onBlockchainEvent?.off(
        BlockchainNotificationType.TxUpdated,
        handleBLockchainTxUpdatedNotification
      );

      onBlockchainEvent?.off(
        BlockchainNotificationType.DocPublished,
        handleBlockchainDocPublishedNotification
      );

      onBlockchainEvent?.off(
        BlockchainNotificationType.DocApproved,
        handleBlockchainDocApprovedNotification
      );
    };
  }, [onBlockchainEvent, document, approveTxs]);

  useEffect(() => {
    getDocument();
    getApproveTxs();
  }, [initialDocument.id]);

  return (
    <div className="max-w-full p-4" style={{ width: "600px" }}>
      {isGetDocumentPending ||
        (isGetApproveTxsPending && (
          <LinearProgress className="absolute top-0 inset-x-0 h-1" />
        ))}
      <header className="modal-header flex justify-between items-center pr-12">
        {document?.title}
        <SmartDealDocumentStatusIndicator status={document?.state} />
      </header>

      <div
        className="flex-1 overflow-auto flex flex-col gap-4"
        style={{ maxHeight: "calc(100vh - 240px)" }}
      >
        {document && (
          <section className="sdd-info-section">
            <div className="info-section-item">
              <span>File</span>
              <span className="info-section-item__value">
                <FileThumbnail url={document.url} />
              </span>
            </div>

            <div className="info-section-item">
              <span>File Hash</span>
              <span className="info-section-item__value">
                {document.md5Hash}
              </span>
            </div>

            <div className="info-section-item">
              <span>File Size</span>
              <span className="info-section-item__value">
                {humanFileSize(document.size)}
              </span>
            </div>

            <div className="info-section-item">
              <span>Added By</span>
              <span
                className="link info-section-item__value"
                onClick={() => navigateToCompany(document.ownerCompany?.id)}
              >
                <div className="flex items-center gap-x-2">
                  <Avatar
                    src={document.ownerCompany?.avatarUrl}
                    sx={{ width: 28, height: 28 }}
                  >
                    <Business fontSize="small" />
                  </Avatar>
                  <span>
                    {document.ownerCompany?.name} ({document.ownerType})
                  </span>
                </div>
              </span>
            </div>

            <div className="info-section-item">
              <span>Uploaded At</span>
              <span className="info-section-item__value">
                {fromNow(document.createdAt)}
              </span>
            </div>
          </section>
        )}

        <section className="DocumentBlockchainFlow sdd-info-section">
          <div className="flex justify-between items-center pr-2">
            <h2 className="text-lg font-medium">Document Blockchain Flow</h2>
          </div>

          <div className="flex justify-center">
            <Timeline position="right">
              {document?.createDocTx && (
                <BlockchainFlowItem
                  className="mb-2"
                  title={`Added by ${document.ownerType}`}
                  timestamp={fromNow(document?.createDocTx.createdAt)}
                  status={document?.createDocTx.status}
                  hideConnector={!approveTxs?.length}
                >
                  <BlockchainFlowTx tx={document?.createDocTx} />
                </BlockchainFlowItem>
              )}

              {approveTxs?.sortBy("createdAt", "asc").map((approveTx, idx) => (
                <BlockchainFlowItem
                  key={approveTx.id}
                  className="mb-2"
                  title={`Approved by ${approveTx.approverType}`}
                  timestamp={fromNow(approveTx.createdAt)}
                  status={approveTx.tx?.status}
                  hideConnector={idx == approveTxs.length - 1}
                >
                  <BlockchainFlowTx tx={approveTx.tx} targetHash={docHash} />
                </BlockchainFlowItem>
              ))}
            </Timeline>
          </div>
        </section>
      </div>
    </div>
  );
}
