import { createSlice } from "@reduxjs/toolkit";
import { TimeWindow } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getCompaniesCountryDistributionAction, getCurrentPrecedingStatisticsAction, getFindOutWhyAction, getOpportunitiesStatisticsAction, getPlanSubscriptionStatisticsAction, getTopExportersAction, getTopImportersAction } from "./dashboard.effects";
import { CompaniesCountryDistribution } from "./types/companies-country-distribution.type";
import { CurrentPrecedingStatistic } from "./types/current-preceding-statistic.type";
import { FindOutWhy } from "./types/find-out-why.type";
import { OpportunitiesStatistics } from "./types/opportunities-statistics.type";
import { PlanSubscriptionStatistic } from "./types/plan-subscription-statistic.type";
import { TopCompany } from "./types/top-company.type";

export const DASHBOARD_STATE_NAME = "dashboard";

export interface DashboardState {
    companiesCountryDistribution: CompaniesCountryDistribution[]
    currentPreceding: {
        statistics: CurrentPrecedingStatistic[],
        window: number
    }
    planSubscription: {
        statistics: PlanSubscriptionStatistic[],
        window: TimeWindow
    },
    opportunities: {
        statistics: OpportunitiesStatistics,
        window: number
    },
    findOutWhy: FindOutWhy,
    topImporters: {
        statistics: TopCompany[],
        window: TimeWindow
    },
    topExporters: {
        statistics: TopCompany[],
        window: TimeWindow
    }
}

export const dashboardState = createSlice({
    name: DASHBOARD_STATE_NAME,
    initialState: {
        companiesCountryDistribution: [],
        currentPreceding: {
            statistics: [],
            window: 30
        },
        planSubscription: {
            statistics: [],
            window: 'all'
        },
        opportunities: {
            window: 30
        },
        findOutWhy: null,
        topImporters: {
            statistics: [],
            window: 'all'
        },
        topExporters: {
            statistics: [],
            window: 'all'
        }
    } as DashboardState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompaniesCountryDistributionAction.fulfilled, (state, action) => {
                state.companiesCountryDistribution = action.payload;
            })
            .addCase(getCurrentPrecedingStatisticsAction.fulfilled, (state, action) => {
                state.currentPreceding.statistics = action.payload;
                state.currentPreceding.window = action.meta.arg.days ?? state.currentPreceding.window;
            })
            .addCase(getPlanSubscriptionStatisticsAction.fulfilled, (state, action) => {
                state.planSubscription.statistics = action.payload;
                state.planSubscription.window = action.meta.arg.window ?? state.planSubscription.window;
            })
            .addCase(getOpportunitiesStatisticsAction.fulfilled, (state, action) => {
                state.opportunities.statistics = action.payload;
                state.opportunities.window = action.meta.arg.days ?? state.opportunities.window;
            })
            .addCase(getFindOutWhyAction.fulfilled, (state, action) => {
                state.findOutWhy = action.payload;
            })
            .addCase(getTopImportersAction.fulfilled, (state, action) => {
                state.topImporters.statistics = action.payload;
                state.topImporters.window = action.meta.arg.window ?? state.topImporters.window;
            })
            .addCase(getTopExportersAction.fulfilled, (state, action) => {
                state.topExporters.statistics = action.payload;
                state.topExporters.window = action.meta.arg.window ?? state.topExporters.window;
            });
        addCommonReducers(builder, DASHBOARD_STATE_NAME);
    },
});

export const dashboardActions = dashboardState.actions;
export const dashboardReducer = dashboardState.reducer;
