import { Add, Close } from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import classNames from "classnames";
import { UrlWithTitle } from "../../types";
import { useModal } from "../../providers/ModalProvider";
import ImageUploaderWithTitleForm from "./ImageUploaderWithTitleForm";

interface IProps {
  image?: UrlWithTitle;
  className?: string;
  onImageRemove?: () => void;
  onFileUpload?: (file: File, params?: Record<string, any>) => Promise<any>;
  onPreviewClicked?: () => void;
}

export default function ImageUploaderWithTitle({
  image,
  className,
  onImageRemove = () => {},
  onFileUpload = () => Promise.resolve(true),
  onPreviewClicked = () => {},
}: IProps) {
  const { openModal } = useModal();

  const openForm = () => {
    openModal((props) => (
      <ImageUploaderWithTitleForm {...props} onFileUpload={onFileUpload} />
    ));
  };

  return image ? (
    <div
      className={classNames("relative w-40 h-40 select-none group", className)}
    >
      <Paper className="w-full h-full overflow-hidden" elevation={3}>
        <img
          src={image.url}
          className="w-full h-full object-fill"
          draggable={false}
        />
        <div
          className="absolute inset-0 flex justify-center items-center rounded-md"
          style={{
            background: "rgba(0, 0, 0, .2)",
          }}
        >
          <Button
            color="white"
            variant="outlined"
            size="small"
            onClick={onPreviewClicked}
          >
            Preview
          </Button>
        </div>
      </Paper>
      <Close
        className="absolute -right-2 -top-2 bg-white border border-gray-300 rounded-full text-gray-500 cursor-pointer p-1 hover:bg-gray-100 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        fontSize="medium"
        onClick={onImageRemove}
      />
    </div>
  ) : (
    <div
      className={classNames(
        "relative w-40 h-40 border-2 border-gray-300 border-dashed  rounded-md flex justify-center items-center cursor-pointer hover:bg-gray-100",
        className
      )}
      onClick={openForm}
    >
      <Add fontSize="large" />
    </div>
  );
}
