import { Add, Search } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";
import useSkipFirstRenderEffect from "../../../hooks/use-skip-first-render-effect";
import {
  SmartDealsStatusFilterText,
  SmartDealsStatusFilters,
} from "../../../utils/coimex-utils";
import {
  getSmartDealsAction,
  getSmartDealsStatusCountsAction,
} from "../blockchain.effects";
import {
  selectIsGetSmartDealsStatusCountsPending,
  selectSmartDealsSearchFilter,
  selectSmartDealsStatusCounts,
  selectSmartDealsStatusFilter,
} from "../blockchain.selectors";
import { SmartDeal } from "../types";
import SmartDealForm from "./SmartDealForm/SmartDealForm";
import SmartDealFormProvider from "../providers/SmartDealFormProvider";
import { useModal } from "../../../providers/ModalProvider";

export default function SmartDealsFilters() {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const selectedStatus = useSelector(selectSmartDealsStatusFilter);
  const statusCounts = useSelector(selectSmartDealsStatusCounts);
  const searchValue = useSelector(selectSmartDealsSearchFilter);
  const isGetSmartDealsStatusCountsPending = useSelector(
    selectIsGetSmartDealsStatusCountsPending
  );
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const dispatch = useDispatch();

  useSkipFirstRenderEffect(() => {
    dispatch(getSmartDealsAction({ filters: { search: debouncedSearch } }));
  }, [debouncedSearch]);

  const handleAddSmartDeal = async () => {
    const smartDeal: SmartDeal = await openModal(
      (props) => (
        <SmartDealFormProvider {...props}>
          <SmartDealForm />
        </SmartDealFormProvider>
      ),
      {
        closeOnClickOutside: false,
        hideCloseButton: true,
      }
    );
    if (smartDeal?.id) {
      dispatch(getSmartDealsAction({}));
      dispatch(getSmartDealsStatusCountsAction({ changeRefresh: true }));
      enqueueSnackbar(`Smart Deal added successfully.`, {
        variant: "info",
      });
    }
  };

  const handleSelectedStatusChange = (status) => {
    dispatch(getSmartDealsAction({ filters: { status } }));
  };

  return (
    <div className="flex justify-between">
      <ToggleBtnGroup
        items={SmartDealsStatusFilters.map((status) => ({
          label: `${SmartDealsStatusFilterText[status]} 
              (${
                isGetSmartDealsStatusCountsPending ? "?" : statusCounts[status]
              })`,
          value: status,
        }))}
        selectedValue={selectedStatus}
        onSelectedValueChanged={handleSelectedStatusChange}
      />

      <div className="flex gap-2 items-center">
        <Button
          className="mr-2 rounded-3xl"
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleAddSmartDeal}
        >
          <Add /> Add Smart Deal
        </Button>

        <TextField
          placeholder="Search(Alt+S)"
          sx={{ width: "180px" }}
          InputProps={{
            className: "search-input",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            autoComplete: "off",
            accessKey: "s",
          }}
          defaultValue={searchValue}
          variant="outlined"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}
