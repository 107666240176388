import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchHitDecoratedProps } from "../../../common/decorators/search-hit.decorator";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import { TimeWindow } from "../../../types";
import { getTimeWindowText } from "../../../utils/coimex-utils";
import { getTopImportersAction } from "../dashboard.effects";
import {
  selectIsGetTopImportersPending,
  selectTopImportersStatistics,
  selectTopImportersWindow,
} from "../dashboard.selectors";
import { TopCompany } from "../types/top-company.type";
import WidgetList from "./WidgetList";
import WidgetPaginator from "./WidgetPaginator";
import WidgetSearch from "./WidgetSearch";
import WidgetSelect from "./WidgetSelect";

const renderHeader = () => (
  <div className="flex justify-between items-center">
    <span>Name</span>
    <span>Requests</span>
  </div>
);

const renderItem = (company: TopCompany) => {
  return (
    <div className="relative flex justify-between items-center text-xs">
      <span className="text-sm">{company.name}</span>
      <span>{company.count}</span>
      <div
        className="absolute left-0 -bottom-1 h-1 rounded-md bg-secondary"
        style={{ right: `${100 - company.percent}%` }}
      />
    </div>
  );
};

const windows: TimeWindow[] = ["all", 30, 90];

export default function TopImporters() {
  const companies = useSelector(selectTopImportersStatistics);
  const [filteredCompanies, setFilteredCompanies] = useState<TopCompany[]>([]);
  const [paginatedCompanies, setPaginatedCompanies] = useState<TopCompany[]>(
    []
  );
  const isPending = useSelector(selectIsGetTopImportersPending);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(7);
  const [search, setSearch] = useState("");
  const selectedWindow = useSelector(selectTopImportersWindow);
  const dispatch = useDispatch();

  const handleSelectedWindowChange = (window: TimeWindow) => {
    dispatch(getTopImportersAction({ window }));
  };

  useEffect(() => {
    const total = companies.sumOf((c) => c.count);
    let filtered = companies.map((c) => ({
      ...c,
      percent: Math.ceil((c.count * 100) / total || 0),
    }));
    if (search) {
      filtered = filtered.search(
        search,
        ...getSearchHitDecoratedProps(TopCompany)
      );
    }
    setFilteredCompanies(filtered);
  }, [companies, search]);

  useEffect(() => {
    setPaginatedCompanies(
      filteredCompanies.slice(page * pageSize, (page + 1) * pageSize)
    );
  }, [filteredCompanies, page, pageSize]);

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full flex flex-col">
        <div className="flex-1 p-widget">
          <WidgetSearch title="Top Importers" onSearch={setSearch} />
          <div className="flex items-center gap-2 text-smm -mt-0.5">
            Requests posted by Importers
            <WidgetSelect
              className="text-smm"
              items={windows.map((w) => ({
                label: getTimeWindowText(w),
                value: w,
              }))}
              selectedValue={selectedWindow}
              onValueChange={handleSelectedWindowChange}
            />
          </div>
          <div className="flex-1 mt-4">
            <WidgetList
              items={paginatedCompanies}
              renderHeader={renderHeader}
              renderItem={renderItem}
            />
          </div>
        </div>
        <div className="WidgetFooter flex justify-end">
          <WidgetPaginator
            page={page}
            pageSize={pageSize}
            total={filteredCompanies.length}
            onPageChange={setPage}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
}
