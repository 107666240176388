import { LocalGasStationOutlined } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useSelector } from "react-redux";
import { blockchainApi } from "../../http/blockchain.api";
import { selectBlockchainNativeToken } from "../../modules/settings/settings.selectors";
import { EstimatedGasFee } from "../../types";

interface IProps {
  method: string;
  args?: () => any[];
  disabled?: boolean;
}

export default function EstimateGasFeeTooltip({
  method,
  args,
  disabled,
  children,
}: PropsWithChildren<IProps>) {
  const nativeToken = useSelector(selectBlockchainNativeToken);
  const [estimatedGasFee, setEstimatedGasFee] = useState<EstimatedGasFee>();
  const [isLoading, setIsLoading] = useState(true);

  const getEstimateGasFee = async () => {
    if (!disabled) {
      setIsLoading(true);
      setEstimatedGasFee(
        await blockchainApi.getEstimateGasFee(method, args?.())
      );
      setIsLoading(false);
    }
  };

  return (
    <Tooltip
      onMouseEnter={getEstimateGasFee}
      title={
        disabled ? null : (
          <div className="flex items-center gap-1">
            <LocalGasStationOutlined
              className="text-sm"
              color={
                isLoading
                  ? "inherit"
                  : estimatedGasFee.error || !estimatedGasFee.balanceSufficient
                  ? "warning"
                  : "inherit"
              }
            />
            {isLoading ? (
              <CircularProgress className="ml-1" size={10} color="white" />
            ) : (
              <span>
                {estimatedGasFee.error ??
                  `${estimatedGasFee.gasFee} ${nativeToken?.symbol}`}
              </span>
            )}
          </div>
        )
      }
    >
      {children as any}
    </Tooltip>
  );
}
