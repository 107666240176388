import Box from "@mui/material/Box";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import Footer from "../../components/Footer/Footer";
import { Screen } from "../../types";
import MainLayoutDrawer from "./MainLayoutDrawer";
import MainLayoutHeader from "./MainLayoutHeader";

export default function MainLayout() {
  const [open, setOpen] = React.useState(true);
  const isLargeScreen = useMediaQuery(Screen.LargeScreen);

  const toggleDraw = () => {
    setOpen((open) => !open);
  };

  React.useLayoutEffect(() => {
    !isLargeScreen && setOpen(false);
  }, [isLargeScreen]);

  return (
    <Box
      className="h-full w-full bg-bg-1 overflow-auto"
      sx={{ display: "flex" }}
    >
      <MainLayoutHeader open={open} toggleDraw={toggleDraw} />
      <MainLayoutDrawer open={open} />
      <Box
        className="flex flex-col"
        component="main"
        sx={{ flexGrow: 1, mt: 8 }}
      >
        <div
          className="overflow-auto p-6 pb-1"
          style={{ flex: "1 0 calc(100vh - 135px)" }}
        >
          <Outlet />
        </div>
        <Footer />
      </Box>
    </Box>
  );
}
