import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store/root.reducer";
import { getMeAction } from "./auth.effects";

export const selectAuthState = (state: RootState) => state.auth;

export const selectMe = createSelector(selectAuthState, (state) => state.me);

export const selectCredentialsTemp = createSelector(selectAuthState, state => state.credentialsTemp);

export const selectIsAuthenticated = createSelector(selectMe, (me) => !!me);

export const selectIsGetMePending = createSelector(
    selectAuthState,
    (state) => state[getMeAction.pending.type]
);

export const selectMeRoles = createSelector(
    selectMe,
    me => me?.roles || []
)