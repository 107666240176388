import { createContext, ReactNode, useContext, useState } from "react";

interface ContextProps {
  ToolbarSlot: ReactNode;
  setToolbarSlot: React.Dispatch<React.SetStateAction<ReactNode>>;
}

const MainLayoutHeaderSlotContext = createContext<ContextProps>(null);

export default function MainLayoutHeaderSlotProvider({ children }) {
  const [ToolbarSlot, setToolbarSlot] = useState<ReactNode>();

  return (
    <MainLayoutHeaderSlotContext.Provider
      value={{ ToolbarSlot, setToolbarSlot }}
    >
      {children}
    </MainLayoutHeaderSlotContext.Provider>
  );
}

export const useMainLayoutHeaderSlot = () =>
  useContext(MainLayoutHeaderSlotContext);
