export enum TreasuryTransactionCategory {
  Internal = "Internal",
  InvestPool = "InvestPool",
  Dex = "Dex",
}

export const TreasuryTransactionCategories = Object.values(
  TreasuryTransactionCategory
);

export function toTreasuryTransactionCategoryIndex(
  category: TreasuryTransactionCategory
) {
  return TreasuryTransactionCategories.indexOf(category);
}
