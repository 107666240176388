import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import { TimeWindow } from "../../../types";
import { getTimeWindowText } from "../../../utils/coimex-utils";
import { getPlanSubscriptionStatisticsAction } from "../dashboard.effects";
import {
  selectIsGetPlanSubscriptionStatisticsPending,
  selectPlanSubscriptionStatistics,
  selectPlanSubscriptionWindow,
} from "../dashboard.selectors";
import { PlanSubscriptionStatistic } from "../types/plan-subscription-statistic.type";
import WidgetSelect from "./WidgetSelect";

const windows: TimeWindow[] = ["all", 30, 90];

const getColor = (subscriptionCount?: number): string => {
  switch (true) {
    case subscriptionCount < 1:
      return "text-white";
  }
};

const getBgColor = (subscriptionCount?: number): string => {
  switch (true) {
    case subscriptionCount < 1:
      return "bg-red-600";

    case subscriptionCount < 2:
      return "bg-red-300";

    case subscriptionCount < 5:
      return "bg-blue-300";

    case subscriptionCount < 8:
      return "bg-blue-400";

    case subscriptionCount < 10:
      return "bg-blue-500";

    case subscriptionCount >= 10:
      return "bg-blue-600";
  }
};

const renderRow = (statistic: PlanSubscriptionStatistic) => (
  <React.Fragment key={statistic.type}>
    <div className="cell pr-2">{statistic.type}</div>
    <div
      className={classNames(
        "cell",
        getColor(statistic.import),
        getBgColor(statistic.import)
      )}
    >
      {statistic.import}
    </div>
    <div
      className={classNames(
        "cell",
        getColor(statistic.export),
        getBgColor(statistic.export)
      )}
    >
      {statistic.export}
    </div>
    <div
      className={classNames(
        "cell",
        getColor(statistic.both),
        getBgColor(statistic.both)
      )}
    >
      {statistic.both}
    </div>
  </React.Fragment>
);

export default function PlanSubscriptions() {
  const statistics = useSelector(selectPlanSubscriptionStatistics);
  const selectedWindow = useSelector(selectPlanSubscriptionWindow);
  const isPending = useSelector(selectIsGetPlanSubscriptionStatisticsPending);
  const dispatch = useDispatch();

  const handleSelectedWindowChange = (window: TimeWindow) => {
    dispatch(getPlanSubscriptionStatisticsAction({ window }));
  };

  return (
    <LoadingOverlay loading={isPending}>
      <div className="PlanSubscriptions w-full h-full flex flex-col">
        <div className="flex-1 flex flex-col p-widget">
          <div className="WidgetHeader flex justify-between items-center">
            Plan Subscriptions
          </div>
          <div className="grid flex-1 mt-2 mb-4 mr-4">
            {["", "Import", "Export", "Both"].map((p) => (
              <div key={p} className="cell -mb-1.5">
                {p}
              </div>
            ))}
            {statistics.map(renderRow)}
          </div>
        </div>
        <div className="WidgetFooter flex items-center">
          <WidgetSelect
            items={windows.map((w) => ({
              label: getTimeWindowText(w),
              value: w,
            }))}
            selectedValue={selectedWindow}
            onValueChange={handleSelectedWindowChange}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
}
