import { Business } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridSortModel,
} from "@mui/x-data-grid";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import SmartDealStateChip from "../../../components/SmartDealStateChip/SmartDealStateChip";
import CometStart from "../../../components/Svg/CometStart";
import { Pagination } from "../../../types";
import { toShortHash } from "../../../utils/blockchain-utils";
import { getSmartDealsAction } from "../../blockchain/blockchain.effects";
import {
  selectIsGetSmartDealsPending,
  selectSmartDeals,
  selectSmartDealsOrderByFilter,
  selectSmartDealsPagination,
} from "../../blockchain/blockchain.selectors";
import { SmartDeal } from "../../blockchain/types";
import { SmartDealPromptPay } from "../../../types/smart-deal-prompt-pay.type";
import { SmartDealPromptPayStatusColor } from "../../../utils/coimex-utils";
import { SmartDealPromptPayStatus } from "../../../types/smart-deal-prompt-pay-status.type";

export default function SmartDealsTable() {
  const smartDeals = useSelector(selectSmartDeals);
  const pagination = useSelector(selectSmartDealsPagination);
  const isGetSmartDealsPending = useSelector(selectIsGetSmartDealsPending);
  const orderBy = useSelector(selectSmartDealsOrderByFilter);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    dispatch(getSmartDealsAction(pg));
  };

  const handleSorting = (sort: GridSortModel) => {
    console.log(sort);
    dispatch(
      getSmartDealsAction({
        filters: { orderBy: sort?.[0] || { field: "createdAt", sort: "desc" } },
      })
    );
  };

  const handleRowClick = (smartDeal: SmartDeal) => {
    navigate(smartDeal.id);
  };

  useEffect(() => {
    setRows(
      (smartDeals?.map((smartDeal, index) => ({
        ...smartDeal,
        rowNo: pagination.page * pagination.pageSize + index + 1,
      })) as any) || []
    );
  }, [smartDeals]);

  const cols = useMemo<GridColDef[]>(
    () => [
      {
        field: "rowNo",
        headerName: "",
        width: 50,
        sortable: false,
      },
      {
        field: "refId",
        headerName: "RefID",
        flex: 1,
      },
      {
        field: "exporterCompany",
        headerName: "Exporter Company",
        renderCell: ({ value }) => (
          <div className="flex items-center gap-x-2">
            <Avatar src={value.avatarUrl} sx={{ width: 28, height: 28 }}>
              <Business fontSize="small" />
            </Avatar>
            <span>{value.name}</span>
          </div>
        ),
        flex: 1,
      },
      {
        field: "importerCompany",
        headerName: "Importer Company",
        renderCell: ({ value }) => (
          <div className="flex items-center gap-x-2">
            <Avatar src={value.avatarUrl} sx={{ width: 28, height: 28 }}>
              <Business fontSize="small" />
            </Avatar>
            <span>{value.name}</span>
          </div>
        ),
        flex: 1,
      },
      {
        field: "createDealTx",
        headerName: "Tx Hash",
        renderCell: ({ value }) =>
          value ? (
            <a
              className="link"
              href={value.txUrl}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {toShortHash(value.txHash)}
            </a>
          ) : (
            "-"
          ),
        flex: 1,
      },
      {
        field: "state",
        headerName: "Status",
        renderCell: ({ value }) => <SmartDealStateChip state={value} />,
        headerAlign: "center",
        align: "center",
        width: 120,
      },
      {
        field: "promptPay",
        headerName: "PP",
        renderCell: ({ value }) =>
          value ? (
            <CometStart
              className={classNames(
                SmartDealPromptPayStatusColor[
                  (value as SmartDealPromptPay).status
                ]
              )}
            />
          ) : (
            "-"
          ),
        headerAlign: "center",
        align: "center",
        width: 68,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
        width: 120,
      },
    ],
    []
  );

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
        loading={isGetSmartDealsPending}
        pagination
        paginationMode="server"
        autoPageSize
        rowCount={pagination.total}
        page={pagination.page}
        sortingMode="server"
        sortModel={orderBy ? [orderBy] : []}
        onSortModelChange={handleSorting}
        onPageChange={(page) => handlePagination({ page })}
        onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No smart deals" />,
          Footer: (props) => (
            <div className="relative">
              <GridFooter {...props} />
              <div className="absolute left-0 top-0 bottom-0 px-6 flex gap-6">
                <div className="flex items-center gap-2">
                  <CometStart
                    className={classNames(
                      SmartDealPromptPayStatusColor[
                        SmartDealPromptPayStatus.UnderReview
                      ]
                    )}
                  />
                  <small>PP Under Review</small>
                </div>

                <div className="flex items-center gap-2">
                  <CometStart
                    className={classNames(
                      SmartDealPromptPayStatusColor[
                        SmartDealPromptPayStatus.Approved
                      ]
                    )}
                  />
                  <small>PP Approved</small>
                </div>

                <div className="flex items-center gap-2">
                  <CometStart
                    className={classNames(
                      SmartDealPromptPayStatusColor[
                        SmartDealPromptPayStatus.Rejected
                      ]
                    )}
                  />
                  <small>PP Rejected</small>
                </div>
              </div>
            </div>
          ),
        }}
        getRowClassName={(params) => "cursor-pointer"}
        onRowClick={({ row }) => handleRowClick(row)}
      />
    </div>
  );
}
