import { useSelector } from "react-redux";
import { Chain } from "viem";
import { Connector } from "wagmi";
import { WalletConnectors } from "../../common/wagmi-config";
import { selectBlockchainConfigs } from "../../modules/settings/settings.selectors";
import { toBlockchainAddressUrl } from "../../utils/blockchain-utils";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import "./ConnectedWalletInfo.scss";
import { Chip } from "@mui/material";

interface IProps {
  address: string;
  connector: Connector;
  chain: Chain;
}

export default function ConnectedWalletInfo({
  address,
  connector,
  chain,
}: IProps) {
  const configs = useSelector(selectBlockchainConfigs);
  const walletConnector = WalletConnectors[connector.id];

  return (
    <div className="w-[480px] max-w-full p-4 flex flex-col">
      <header className="modal-header w-full">Connected Wallet Info</header>
      <section className="connected-wallet-info-section">
        <div className="info-section-item">
          <span>Network: </span>
          <span className="info-section-item__value flex items-center gap-4">
            {chain ? (
              <>
                {chain.name}
                {chain.testnet && (
                  <Chip label="TEST" color="yellow" size="small" />
                )}
              </>
            ) : (
              <span>N/A</span>
            )}
          </span>
        </div>

        <div className="info-section-item">
          <span>Block Explorer: </span>
          <span className="info-section-item__value flex items-center gap-4">
            {chain ? (
              <a
                className="link"
                href={chain.blockExplorers.default.url}
                target="_blank"
              >
                {chain.blockExplorers.default.url}
              </a>
            ) : (
              <span>N/A</span>
            )}
          </span>
        </div>

        <div className="info-section-item">
          <span>Native Currency: </span>
          <span className="info-section-item__value flex items-center gap-4">
            {chain ? chain?.nativeCurrency.symbol : <span>N/A</span>}
          </span>
        </div>

        <div className="info-section-item">
          <span>Wallet Provider: </span>
          <span className="info-section-item__value flex items-center gap-4">
            {walletConnector ? (
              <>
                {walletConnector.name}
                <img className="w-5 h-5" src={walletConnector.icon} />
              </>
            ) : (
              <span>N/A</span>
            )}
          </span>
        </div>

        <div className="info-section-item">
          <span>Account: </span>
          <span className="info-section-item__value">
            <a
              className="link"
              href={toBlockchainAddressUrl(
                configs?.addressExplorerUrl,
                address
              )}
              target="_blank"
            >
              {address}
            </a>
            <CopyToClipboard text={address} />
          </span>
        </div>
      </section>
    </div>
  );
}
