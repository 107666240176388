import { createAsyncThunk } from "@reduxjs/toolkit";
import { appActions } from "../app/app.state";
import { AppPage, AppRefreshStatus } from "../../types";
import { commodityTokenApi } from "../../http/commodity-tokens.api";

export const getCommodityTokensAction = createAsyncThunk(
  "commodityTokens/getCommodityTokens",
  async ({ changeRefresh }: { changeRefresh: boolean }, thunk) => {
    try {
      changeRefresh &&
        thunk.dispatch(
          appActions.changeRefresh({
            status: AppRefreshStatus.InProgress,
            page: AppPage.CommodityTokens,
          })
        );

      const commodityTokens = await commodityTokenApi.getCommodityTokens();
      return commodityTokens;
    } finally {
      changeRefresh &&
        thunk.dispatch(
          appActions.changeRefresh({
            status: AppRefreshStatus.Idle,
            page: AppPage.CommodityTokens,
          })
        );
    }
  }
);
