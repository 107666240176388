import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getAccountBalancesActionsAction,
  getRwaContractBalancesAction,
  getTreasuryTransactionsAction,
} from "./treasury.effects";
import { InvestPoolType } from "../../types";
import { selectInvestState } from "../invest/invest.selectors";
import { type } from "os";

export const selectTreasuryState = (state: RootState) => state.treasury;

export const selectAccountBalances = createSelector(
  selectTreasuryState,
  (state) => state.accountBalances || []
);

export const selectIsGetAccountBalancesPending = createSelector(
  selectTreasuryState,
  (state) => state[getAccountBalancesActionsAction.pending.type]
);

export const selectRwaContractBalances = createSelector(
  selectTreasuryState,
  (state) => state.rwaContractBalances
);

export const selectIsGetRwaContractBalancesPending = createSelector(
  selectTreasuryState,
  (state) => state[getRwaContractBalancesAction.pending.type]
);

export const selectedTreasuryTransactionsSelectedCategory = createSelector(
  selectTreasuryState,
  (state) => state.selectedTransactionsCategory
);

export const selectTransactionsCategoriesPageSizes = createSelector(
  selectTreasuryState,
  (state) => state.transactionsCategoriesPageSizes
);

export const selectTreasuryTransactions = createSelector(
  selectTreasuryState,
  (state) => state.transactions
);

export const selectIsGetTreasuryTransactionsPending = createSelector(
  selectTreasuryState,
  (state) => state[getTreasuryTransactionsAction.pending.type]
);
