export function removeNull<T>(obj: T): Partial<T> {
  const newObj = {} as Partial<T>;
  for (const key in obj) {
    if (obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function removeNullOrEmpty<T>(obj: T): Partial<T> {
  const newObj = {} as Partial<T>;
  for (const key in obj) {
    //@ts-ignore
    if (obj[key] !== null && obj[key] !== "") {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function isNullOrEmpty(obj: any): boolean {
  if (obj == null) {
    return true;
  }

  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }

  return false;
}
