import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
} from "@mui/material";
import classNames from "classnames";
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

export type MenuOnClose<TResult = any> = (result?: TResult) => void;

export interface Props {
  openMenu: <TResult = unknown>(
    component: (props?: { onClose: MenuOnClose<TResult> }) => ReactElement,
    anchorEl: HTMLElement,
    opts?: {
      className?: string;
      placement?: PopperPlacementType;
      closeOnClickOutside?: boolean;
    }
  ) => Promise<TResult>;
  isOpen: boolean;
}

const MenuContext = createContext<Props>(null);
const DEFAULT_OPTS: Parameters<Props["openMenu"]>[2] = {
  closeOnClickOutside: true,
  placement: "auto",
};
const TRANSITION = 200;

export default function MenuProvider({ children }) {
  const [Component, setComponent] =
    useState<Parameters<Props["openMenu"]>[0]>();
  const [anchorEl, setAnchorEl] =
    useState<Parameters<Props["openMenu"]>[1]>(null);
  const [opts, setOpts] = useState<Parameters<Props["openMenu"]>[2]>(null);
  const resolveRef = useRef<((any) => void) | null>(() => {});
  const isOpen = Boolean(anchorEl);
  const causedByActionButton = useRef(false);
  const contentRef = useRef<HTMLDivElement>();

  const openMenu: Props["openMenu"] = (component, el, opts): Promise<any> => {
    return new Promise((resolve) => {
      resolveRef.current = resolve;
      causedByActionButton.current = true;

      if (anchorEl?.id && anchorEl.id === el.id) {
        onClose();
      } else {
        setComponent(() => component);
        if (opts) {
          setOpts({ ...DEFAULT_OPTS, ...opts });
        }
        setAnchorEl(el);
      }
    });
  };

  const onClose = useCallback(
    (result?: any) => {
      setAnchorEl(null);
      resolveRef.current?.(result);
      setTimeout(() => {
        setComponent(null);
        setOpts(DEFAULT_OPTS);
      }, TRANSITION);
    },
    [resolveRef.current]
  );

  const handleClickOutside = (e: MouseEvent) => {
    if (!causedByActionButton.current) {
      if (opts?.closeOnClickOutside) {
        const rect = contentRef.current.getBoundingClientRect();
        const { clientX: x, clientY: y } = e;

        if (
          x < rect.left ||
          x > rect.right ||
          y < rect.top ||
          y > rect.bottom
        ) {
          onClose();
        }
      }
    } else {
      causedByActionButton.current = false;
    }
  };

  return (
    <MenuContext.Provider value={{ openMenu, isOpen }}>
      <>
        {children}
        <ClickAwayListener onClickAway={handleClickOutside}>
          <Popper
            className="z-1"
            open={isOpen}
            anchorEl={anchorEl}
            transition
            popperOptions={{ placement: opts?.placement || "bottom" }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={TRANSITION}>
                <Paper
                  className={classNames(
                    "mt-1 overflow-auto rounded-md border",
                    opts?.className
                  )}
                  ref={contentRef}
                >
                  {Component ? <Component onClose={onClose} /> : null}
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      </>
    </MenuContext.Provider>
  );
}

export function useMenu() {
  return useContext(MenuContext);
}
