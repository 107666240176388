import { yupResolver } from "@hookform/resolvers/yup";
import { PercentOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useFocus from "../../../hooks/use-focus";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { CommodityToken } from "../../../types";
import {
  toDecimalsAmount,
  toIntAndDecimals,
} from "../../../utils/blockchain-utils";
import EstimateGasFeeTooltip from "../../../components/EstimateGasFeeTooltip/EstimateGasFeeTooltip";

interface IProps {
  token: CommodityToken;
  close?: (modified?: boolean) => void;
}

export default function SetCommodityTokenCommissionForm({
  token,
  close,
}: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const { enqueueSnackbar } = useSnackbar();
  const symbols = useMemo<CommodityToken["symbol"][]>(
    () => Object.keys(token.prices),
    [token.prices]
  );
  const validationSchema = Yup.object().shape({
    symbol: Yup.string().required(),
    buyCommission: Yup.number().required().min(0).max(100),
    sellCommission: Yup.number().required().min(0).max(100),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      symbol: Object.keys(token.prices)?.[0],
      buyCommission: null,
      sellCommission: null,
    },
  });
  const symbol = form.watch("symbol");
  const buyCommission = +(form.watch("buyCommission") || undefined);
  const sellCommission = +(form.watch("sellCommission") || undefined);
  const selectedToken = token.prices[symbol];

  const getEstimateGasFeeArgs = () => {
    const { buyCommission, sellCommission } = form.getValues();
    const { n: buyN, d: buyD } = toIntAndDecimals(buyCommission);
    const { n: sellN, d: sellD } = toIntAndDecimals(sellCommission);
    const maxDecimals = Math.max(buyD, sellD);

    return [
      token.address,
      selectedToken.address,
      buyN * Math.pow(10, maxDecimals - buyD),
      sellN * Math.pow(10, maxDecimals - sellD),
      maxDecimals + 2,
    ];
  };

  const onSubmit = async ({ symbol, buyCommission, sellCommission }) => {
    try {
      setIsSubmitting(true);
      await commodityTokenApi.setTokenCommission(
        token.address,
        token.prices[symbol].address,
        buyCommission,
        sellCommission
      );
      close(true);
      enqueueSnackbar("Token commission was set successfully", {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  const renderChange = (oldCommission: number, newCommission: number) => {
    const change = newCommission - oldCommission;
    return (
      <div className="mt-2 ml-3.5 flex flex-col text-[13px] opacity-70">
        <div className="grid grid-cols-[120px_1fr]">
          Old commission:
          <span>{oldCommission}%</span>
        </div>

        <div className="grid grid-cols-[120px_1fr]">
          <span>Change:</span>
          <span>
            {change > 0 ? "+" : change < 0 ? "-" : ""}
            {isNaN(newCommission)
              ? ""
              : (newCommission - oldCommission).toFixed(3) + "%"}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedToken) {
      form.setValue("buyCommission", selectedToken.buyCommissionPercentage);
      form.setValue("sellCommission", selectedToken.sellCommissionPercentage);
    }
  }, [selectedToken]);

  return (
    <form className="p-4 w-[460px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        Set {token.symbol}-{token.name} Commission
      </header>

      <div className="flex flex-col gap-8">
        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Token</InputLabel>
          <Select
            label="Token"
            {...form.register("symbol")}
            defaultValue={symbols?.[0]}
            error={!!form.formState.errors?.symbol}
          >
            {symbols.map((symbol) => (
              <MenuItem key={symbol} value={symbol}>
                {symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="flex gap-4">
          <div>
            <TextField
              inputRef={inputRef}
              className="w-full"
              {...form.register("buyCommission")}
              inputMode="decimal"
              label="Buy Commission"
              variant="filled"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentOutlined fontSize="small" />
                  </InputAdornment>
                ),
              }}
              error={!!form.formState.errors?.buyCommission}
            />

            {selectedToken &&
              renderChange(
                selectedToken.buyCommissionPercentage,
                buyCommission
              )}
          </div>

          <div>
            <TextField
              className="w-full"
              {...form.register("sellCommission")}
              inputMode="decimal"
              label="Sell Commission"
              variant="filled"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentOutlined fontSize="small" />
                  </InputAdornment>
                ),
              }}
              error={!!form.formState.errors?.sellCommission}
            />

            {selectedToken &&
              renderChange(
                selectedToken.sellCommissionPercentage,
                sellCommission
              )}
          </div>
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <EstimateGasFeeTooltip
          method="setCommission"
          args={getEstimateGasFeeArgs}
          disabled={!form.formState.isValid}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </EstimateGasFeeTooltip>

        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
