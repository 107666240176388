import { useDispatch } from "react-redux";
import { initializeFirebaseAction } from "../modules/app/app.effects";
import { getCompaniesStatusCountsAction } from "../modules/companies/companies.effects";
import { getNotificationsStatusAction } from "../modules/notifications/notifications.effects";
import { getOffersStatusCountsAction } from "../modules/offers/offers.effects";
import {
  getPlanUpgradeRequestsAction,
  getPlansAction,
} from "../modules/plans/plans.effects";
import { getRequestsStatusCountsAction } from "../modules/requests/requets.effects";
import { getConfigsAction } from "../modules/settings/settings.effects";
import useAuthenticated from "./use-authenticated";
import { getSmartDealsStatusCountsAction } from "../modules/blockchain/blockchain.effects";
import { getCommodityTokensAction } from "../modules/commodity-tokens/commodity-tokens.effects";
import useRole from "./use-role";
import { Roles } from "../types";

export default function useAppInitializer() {
  const { hasRole } = useRole();
  const dispatch = useDispatch();

  const init = async () => {
    dispatch(getConfigsAction());
    dispatch(getPlansAction());
    dispatch(initializeFirebaseAction());
    dispatch(getCompaniesStatusCountsAction({ changeRefresh: false }));
    dispatch(getOffersStatusCountsAction({ changeRefresh: false }));
    dispatch(getRequestsStatusCountsAction({ changeRefresh: false }));
    dispatch(getNotificationsStatusAction());
    dispatch(getPlanUpgradeRequestsAction());

    if (hasRole(Roles.BlockchainAdmin)) {
      dispatch(getSmartDealsStatusCountsAction({ changeRefresh: false }));
      dispatch(getCommodityTokensAction({ changeRefresh: false }));
    }
  };

  useAuthenticated(() => {
    init();
  });
}
