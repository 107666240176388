import { useCallback } from "react";
import { Roles } from "../types";
import { useSelector } from "react-redux";
import { selectMeRoles } from "../modules/auth/auth.selectors";

export default function useRole() {
    const meRoles = useSelector(selectMeRoles);

    const hasRole = useCallback((role: Roles) => {
        return meRoles.some(r => r == role);
    }, [meRoles]);

    return { hasRole };
}