import { Add, Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import ToggleBtnGroup from "../../../components/ToggleBtnGroup/ToggleBtnGroup";
import useSkipFirstRenderEffect from "../../../hooks/use-skip-first-render-effect";
import { useModal } from "../../../providers/ModalProvider";
import { Company } from "../../../types";
import {
  CompaniesStatusFilterText,
  CompaniesStatusFilters,
} from "../../../utils/coimex-utils";
import {
  getCompaniesAction,
  getCompaniesStatusCountsAction,
} from "../companies.effects";
import {
  selectCompaniesSearchFilter,
  selectCompaniesStatusCounts,
  selectCompaniesStatusFilter,
  selectCompaniesTypeFilter,
  selectIsGetCompaniesStatusCountsPending,
} from "../companies.selectors";
import CompanyForm from "./CompanyForm/CompanyForm";
import CompanyFormProvider from "./CompanyForm/CompanyFormProvider";

export default function CompaniesFilters() {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const selectedStatus = useSelector(selectCompaniesStatusFilter);
  const selectedType = useSelector(selectCompaniesTypeFilter);
  const statusCounts = useSelector(selectCompaniesStatusCounts);
  const searchValue = useSelector(selectCompaniesSearchFilter);
  const isGetCompaniesStatusCountsPending = useSelector(
    selectIsGetCompaniesStatusCountsPending
  );
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const dispatch = useDispatch();

  useSkipFirstRenderEffect(() => {
    dispatch(getCompaniesAction({ filters: { search: debouncedSearch } }));
  }, [debouncedSearch]);

  const handleAddCompany = async () => {
    const company: Company = await openModal(
      (props) => (
        <CompanyFormProvider {...props}>
          <CompanyForm />
        </CompanyFormProvider>
      ),
      {
        closeOnClickOutside: false,
      }
    );
    if (company) {
      dispatch(getCompaniesAction({}));
      dispatch(getCompaniesStatusCountsAction({ changeRefresh: true }));
      enqueueSnackbar(`Company ${company.name} added successfully.`, {
        variant: "info",
      });
    }
  };

  const handleSelectedStatusChange = (status) => {
    dispatch(getCompaniesAction({ filters: { status } }));
  };

  const handleTypeChange = (type) => {
    dispatch(getCompaniesAction({ filters: { type } }));
  };

  return (
    <div className="flex justify-between">
      <ToggleBtnGroup
        items={CompaniesStatusFilters.map((status) => ({
          label: `${CompaniesStatusFilterText[status]} 
          (${isGetCompaniesStatusCountsPending ? "?" : statusCounts[status]})`,
          value: status,
        }))}
        selectedValue={selectedStatus}
        onSelectedValueChanged={handleSelectedStatusChange}
      />

      <div className="flex gap-2 items-center">
        <Button
          className="mr-2 rounded-3xl"
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleAddCompany}
        >
          <Add /> Add Company
        </Button>
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Type</InputLabel>
          <Select
            label="Type"
            value={selectedType}
            onChange={(e) => handleTypeChange(e.target.value)}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="Importer">Importer</MenuItem>
            <MenuItem value="Exporter">Exporter</MenuItem>
            <MenuItem value="Both">Both</MenuItem>
          </Select>
        </FormControl>
        <TextField
          placeholder="Search(Alt+S)"
          sx={{ width: "180px" }}
          InputProps={{
            className: "search-input",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            autoComplete: "off",
            accessKey: "s",
          }}
          defaultValue={searchValue}
          variant="outlined"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}
