import { ExpandMore, FilterAltOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Badge,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MouseEvent, useId, useMemo, useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { investApi } from "../../../http";
import { MenuOnClose, useMenu } from "../../../providers/MenuProvider";
import { InvestPool, InvestPoolType, InvestPoolTypes } from "../../../types";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { InvestPoolTypeText } from "../../../utils/coimex-utils";
import { isNullOrEmpty, removeNullOrEmpty } from "../../../utils/obj-utils";
import { TreasuryInvestPoolTransactionsFilters as TreasuryInvestPoolTransactionsFiltersType } from "../types/treasury-invest-pool-transactions-filters.type";
import { type } from "os";

interface FiltersProps {
  preFilters?: TreasuryInvestPoolTransactionsFiltersType;
  investPools: Pick<InvestPool, "id" | "name" | "type" | "investToken">[];
  isGetInvestPoolsPending: boolean;
  onClose: MenuOnClose<TreasuryInvestPoolTransactionsFiltersType>;
}

const Filters = ({
  preFilters,
  investPools,
  isGetInvestPoolsPending,
  onClose,
}: FiltersProps) => {
  const [filters, setFilters] =
    useState<TreasuryInvestPoolTransactionsFiltersType>(preFilters);
  const investTokens = useMemo(
    () => investPools.distinct((pool) => pool.investToken.address),
    [investPools]
  );

  const patchFilters = (
    update: Partial<TreasuryInvestPoolTransactionsFiltersType>
  ) => {
    let updated = { ...filters, ...update };

    if (
      update.poolType &&
      updated.poolId &&
      investPools.find((p) => p.id == updated.poolId).type != update.poolType
    ) {
      updated = { ...updated, poolId: null };
    }

    if (
      update.poolId &&
      updated.poolType &&
      updated.poolType != investPools.find((p) => p.id == update.poolId).type
    ) {
      update = { ...update, poolType: null };
    }

    setFilters(removeNullOrEmpty(updated));
  };

  const hasFilter = (
    key: keyof TreasuryInvestPoolTransactionsFiltersType
  ): boolean => {
    return filters?.[key] != null;
  };

  const isDirty = useMemo(
    () => JSON.stringify(preFilters || {}) != JSON.stringify(filters || {}),
    [preFilters, filters]
  );

  const errors = useMemo(() => {
    let errors: TreasuryInvestPoolTransactionsFiltersType = {};
    if (filters?.from && !isValidBlockchainAddress(filters.from)) {
      errors["from"] = "Address is not valid";
    }

    return errors;
  }, [filters]);

  return (
    <div className="p-4 w-[380px] ">
      <div className="flex items-center justify-between mb-4">
        <Button
          color="info"
          variant="contained"
          size="small"
          disabled={!isDirty || !isNullOrEmpty(errors)}
          onClick={() => onClose(filters)}
        >
          Apply Changes
        </Button>
        <Button
          color="info"
          size="small"
          disabled={!Object.keys(filters || {}).length}
          onClick={() => setFilters({} as any)}
        >
          Clear Filters
        </Button>
      </div>

      <Accordion defaultExpanded={hasFilter("timeRange")}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Time Range
        </AccordionSummary>
        <AccordionDetails>
          <DateRangePicker
            value={filters?.timeRange}
            onChange={(e) => patchFilters({ timeRange: e })}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={hasFilter("poolId") || hasFilter("poolType")}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Invest Pool
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col gap-6">
            <FormControl fullWidth size="small">
              <InputLabel>Pool Type</InputLabel>
              <Select
                value={filters?.poolType || "All"}
                label="Pool Type"
                onChange={(e) => {
                  const value = e.target.value;
                  patchFilters({
                    poolType: value == "All" ? null : (value as InvestPoolType),
                  });
                }}
              >
                {["All", ...InvestPoolTypes].map((type) => (
                  <MenuItem key={type} value={type}>
                    {type == "All" ? type : InvestPoolTypeText[type]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              value={filters?.poolId || ""}
              onChange={(_, value) => patchFilters({ poolId: value })}
              options={investPools.map((p) => p.id)}
              getOptionLabel={(poolId) =>
                investPools.find((p) => p.id == poolId)?.name || ""
              }
              renderInput={(params) => (
                <div className="relative">
                  <TextField {...params} label="Invest Pool" size="small" />
                  {isGetInvestPoolsPending && (
                    <LinearProgress className="absolute top-0 left-0 right-0 h-0.5" />
                  )}
                </div>
              )}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={hasFilter("token")}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Invest Token
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth size="small">
            <InputLabel>Token</InputLabel>
            <Select
              value={filters?.token || "All"}
              label="Token"
              onChange={(e) => {
                const value = e.target.value;
                patchFilters({
                  token: value == "All" ? null : (value as string),
                });
              }}
            >
              {["All", ...investTokens.map((p) => p.investToken)].map(
                (token) => (
                  <MenuItem
                    key={
                      token == "All"
                        ? token
                        : (token as InvestPool["investToken"]).address
                    }
                    value={
                      token == "All"
                        ? null
                        : (token as InvestPool["investToken"]).address
                    }
                  >
                    {token == "All"
                      ? token
                      : (token as InvestPool["investToken"]).symbol}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={hasFilter("from")}>
        <AccordionSummary expandIcon={<ExpandMore />}>From</AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder="Address starts with 0x..."
            value={filters?.from || ""}
            onChange={(e) => patchFilters({ from: e.target.value })}
            size="small"
            fullWidth
            error={!!errors?.from}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

interface IProps {
  value: TreasuryInvestPoolTransactionsFiltersType;
  onChange: (filters: TreasuryInvestPoolTransactionsFiltersType) => void;
}

export default function TreasuryInvestPoolTransactionsFilters({
  value,
  onChange,
}: IProps) {
  const [investPools, setInvestPools] = useState<
    Pick<InvestPool, "id" | "name" | "type" | "investToken">[]
  >([]);
  const [isGetInvestPoolsPending, setIsGetInvestPoolsPending] = useState(true);
  const { openMenu } = useMenu();
  const menuTriggerId = useId();

  const getInvestPoolsNames = async () => {
    setIsGetInvestPoolsPending(true);
    setInvestPools(await investApi.getInvestPoolsNames());
    setIsGetInvestPoolsPending(false);
  };

  const handleClick = async (e: MouseEvent<HTMLElement>) => {
    const result = await openMenu<TreasuryInvestPoolTransactionsFiltersType>(
      (props) => (
        <Filters
          {...props}
          preFilters={value}
          investPools={investPools}
          isGetInvestPoolsPending={isGetInvestPoolsPending}
        />
      ),
      e.currentTarget as HTMLElement,
      {
        className: "mr-2 bg-bg-1",
        placement: "bottom-start",
      }
    );

    result && onChange(result);
  };

  useEffectOnce(() => {
    getInvestPoolsNames();
  });

  return (
    <Badge
      className="filter-badge absolute top-[10px] right-4"
      badgeContent={isNullOrEmpty(value) ? 0 : 1}
      variant="dot"
      color="secondary"
    >
      <IconButton id={menuTriggerId} size="small" onClick={handleClick}>
        <FilterAltOutlined className="text-[22px]" />
      </IconButton>
    </Badge>
  );
}
