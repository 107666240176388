import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import EstimateGasFeeTooltip from "../../../components/EstimateGasFeeTooltip/EstimateGasFeeTooltip";
import useFocus from "../../../hooks/use-focus";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import { toDecimalsAmount } from "../../../utils/blockchain-utils";

interface IProps {
  close?: (modified?: boolean) => void;
}

export default function AddNewCommodityTokenForm({ close }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<any>();
  useFocus(inputRef);
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().trim().min(1),
    symbol: Yup.string().required().trim().min(1),
    decimals: Yup.number().required().min(0),
    amount: Yup.number().min(0),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      symbol: "",
      decimals: null,
      amount: 0,
    },
  });

  const getEstimateGasFeeArgs = () => {
    const { name, symbol, decimals, amount } = form.getValues();
    return [name, symbol, decimals, toDecimalsAmount(amount, decimals)];
  };

  const onSubmit = async (value) => {
    try {
      setIsSubmitting(true);
      await commodityTokenApi.addNewToken(value);
      close(true);
      enqueueSnackbar("Token was added successfully", {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="p-4 w-[320px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">Add New Token</header>

      <div className="flex flex-col gap-8">
        <TextField
          inputRef={inputRef}
          className="w-full"
          {...form.register("name")}
          inputMode="decimal"
          label="Name"
          variant="filled"
          size="small"
          error={!!form.formState.errors?.name}
        />

        <TextField
          className="w-full"
          {...form.register("symbol")}
          inputMode="decimal"
          label="Symbol"
          variant="filled"
          size="small"
          error={!!form.formState.errors?.symbol}
        />

        <TextField
          className="w-full"
          {...form.register("decimals")}
          inputMode="decimal"
          label="Decimals"
          variant="filled"
          size="small"
          error={!!form.formState.errors?.decimals}
        />

        <TextField
          className="w-full"
          {...form.register("amount")}
          inputMode="decimal"
          label="Initial Supply"
          variant="filled"
          size="small"
          error={!!form.formState.errors?.amount}
        />
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <EstimateGasFeeTooltip
          method="mintNewRWA"
          args={getEstimateGasFeeArgs}
          disabled={!form.formState.isValid}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </EstimateGasFeeTooltip>

        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
