export enum AppPage {
    Dashboard = 'Dashboard',
    Companies = 'Companies',
    Payments = 'Payments',
    Offers = 'Offers',
    Requests = 'Requests',
    Plans = 'Plans',
    SmartDeals = 'SmartDeals',
    CommodityTokens = 'CommodityTokens',
    CommodityTokensSettings = 'CommodityTokensSettings',
    PromptPayPools = 'PromptPayPools',
    Treasury = 'Treasury',
    Schedules = 'Schedules',
    Settings = 'Settings'
}