import api from ".";
import { AccountBalanceNotifier } from "../modules/treasury/types/account-balance-notifier.entity";
import { RwaContractBalances } from "../modules/treasury/types/rwa-contract-balances.type";
import { SaveAccountBalanceNotifierRequest } from "../modules/treasury/types/save-account-balance-notifier-request.type";
import { TreasuryTransactionCategory } from "../modules/treasury/types/treasury-transactions-category.type";
import { UpdateRwaContractBalanceRequest } from "../modules/treasury/types/update-rwa-balance-request.type";
import {
  CommodityTokenTx,
  InvestPoolTx,
  Paginated,
  TreasuryAccountBalance,
} from "../types";

export const treasuryApi = {
  async getAccountBalances(): Promise<TreasuryAccountBalance[]> {
    return (await api.get("/treasury-management/account-balances")).data;
  },
  async saveAccountBalance(
    dto: SaveAccountBalanceNotifierRequest
  ): Promise<AccountBalanceNotifier> {
    return (await api.post("/treasury-management/account-balances", dto)).data;
  },
  deleteAccountBalance(id: TreasuryAccountBalance["id"]): Promise<void> {
    return api.delete(`/treasury-management/account-balances/${id}`);
  },
  async getRwaContractBalances(): Promise<RwaContractBalances> {
    return (await api.get("/treasury-management/rwa-contract-balances")).data;
  },
  updateRwaContractBalance(
    dto: UpdateRwaContractBalanceRequest
  ): Promise<void> {
    return api.put("/treasury-management/rwa-contract-balances", dto);
  },
  async getTreasuryTxs(
    category: TreasuryTransactionCategory,
    params
  ): Promise<Paginated<CommodityTokenTx | InvestPoolTx>> {
    return api.get(`/treasury-management/txs/${category}`, { params });
  },
};
