import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useSendTransaction,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { selectBlockchainNativeToken } from "../../settings/settings.selectors";

const abi = [
  {
    type: "function",
    name: "transfer",
    stateMutability: "nonpayable",
    inputs: [
      {
        name: "recipient",
        type: "address",
      },
      {
        name: "amount",
        type: "uint256",
      },
    ],
    outputs: [
      {
        type: "bool",
      },
    ],
  },
];

export default function useTransferToken() {
  const nativeToken = useSelector(selectBlockchainNativeToken);
  const { enqueueSnackbar } = useSnackbar();

  const {
    sendTransaction: transferNative,
    data: nativeTxHash,
    isPending: isTransferNativePending,
    error: transferNativeError,
  } = useSendTransaction();

  const {
    writeContract: transferErc20,
    data: erc20TxHash,
    isPending: isTransferErc20Pending,
    error: transferErc20Error,
  } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash: nativeTxHash || erc20TxHash,
    });

  const isPending =
    isTransferNativePending || isTransferErc20Pending || isConfirming;
  const isSuccess = isConfirmed;
  const error = transferNativeError || transferErc20Error;

  const transferToken = useCallback(
    (to: string, tokenAddress: string, amount: bigint) => {
      if (tokenAddress == nativeToken.address) {
        transferNative({ to, value: amount } as any);
      } else {
        // @ts-ignore
        transferErc20({
          abi,
          address: tokenAddress,
          functionName: "transfer",
          args: [to, amount],
        } as any);
      }
    },
    []
  );

  useEffect(() => {
    if (error && !error.message.includes("User rejected the request")) {
      enqueueSnackbar(`Error transferring token: ${error.message}`, {
        variant: "error",
      });
    }
  }, [error]);

  return { transferToken, isPending, isSuccess, error };
}
