import { InboxOutlined, OpenInNew } from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BlockchainConfigurations,
  InvestPoolTx,
  InvestPoolType,
} from "../../../types";
import {
  toBlockchainAddressUrl,
  toShortHash,
} from "../../../utils/blockchain-utils";
import { InvestPoolTypeText } from "../../../utils/coimex-utils";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import WidgetList from "../../dashboard/components/WidgetList";
import WidgetPaginator from "../../dashboard/components/WidgetPaginator";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { getTreasuryTransactionsAction } from "../treasury.effects";
import {
  selectTransactionsCategoriesPageSizes,
  selectTreasuryTransactions,
} from "../treasury.selectors";
import { TreasuryInvestPoolTransactionsFilters as TreasuryInvestPoolTransactionsFiltersType } from "../types/treasury-invest-pool-transactions-filters.type";
import { TreasuryTransactionCategory } from "../types/treasury-transactions-category.type";
import TreasuryInvestPoolTransactionsFilters from "./TreasuryInvestPoolTransactionsFilters";

const renderHeader = () => (
  <div className="flex justify-between items-center gap-10">
    <span className="flex-1 pl-1">Time</span>
    <span className="flex-1 text-center">Pool Type</span>
    <span className="flex-1">Pool Name</span>
    <span className="flex-1 text-right">Amount</span>
    <span className="flex-1 text-center hidden md:block">From</span>
    <span className="flex-[0_0_30px] text-center">TX</span>
  </div>
);

const renderItem = (tx: InvestPoolTx, configs: BlockchainConfigurations) => {
  return (
    <div className="flex justify-between items-center gap-10 text-sm">
      <span className="flex-1 pl-1">
        {moment(tx.blockTimestamp).fromNow().capitalizeFirstLetter()}
      </span>
      <span className="flex-1 text-center">
        {InvestPoolTypeText[tx.pool.type]}
      </span>
      <Link
        className="flex-1 "
        to={`/investment-pools/${
          tx.pool.type == InvestPoolType.PromptPay ? "prompt-pay-pools" : ""
        }/${tx.pool.id}`}
      >
        {tx.pool.name}
      </Link>
      <span className="flex-1 text-right">
        {formatNumberWithSeparator(tx.valueFormatted)} {tx.tokenSymbol}
      </span>
      <a
        className="flex-1 text-center hidden md:block"
        href={toBlockchainAddressUrl(configs?.addressExplorerUrl, tx.from)}
        target="_blank"
      >
        {toShortHash(tx.from)}
      </a>
      <span className="flex-[0_0_30px] text-center">
        <a href={tx.txUrl} target="_blank">
          <OpenInNew className="text-base" />
        </a>
      </span>
    </div>
  );
};

export default function TreasuryInvestPoolTransactions() {
  const configs = useSelector(selectBlockchainConfigs);
  const paginatedTransactions = useSelector(selectTreasuryTransactions);
  const pageSizes = useSelector(selectTransactionsCategoriesPageSizes);
  const [page, setPage] = useState(0);
  const [filters, setFilters] =
    useState<TreasuryInvestPoolTransactionsFiltersType>();
  const [invalidateTransactionsObj, setInvalidateTransactionsObj] = useState(
    {}
  );
  const dispatch = useDispatch();

  const getTreasuryTransactions = useCallback(
    _.debounce(
      (page: number, filters: TreasuryInvestPoolTransactionsFiltersType) => {
        dispatch(
          getTreasuryTransactionsAction({
            page,
            filters: filters
              ? ({
                  ...filters,
                  timeRange: filters.timeRange
                    ? {
                        start: moment(filters.timeRange.start)
                          .startOf("day")
                          .valueOf(),
                        end: moment(filters.timeRange.end)
                          .endOf("day")
                          .valueOf(),
                      }
                    : null,
                } as TreasuryInvestPoolTransactionsFiltersType)
              : null,
          })
        );
      },
      100
    ),
    []
  );

  useEffect(() => {
    getTreasuryTransactions(page, filters);
  }, [page, invalidateTransactionsObj]);

  useEffect(() => {
    setPage(0);
    setInvalidateTransactionsObj({});
  }, [filters]);

  return paginatedTransactions?.category ==
    TreasuryTransactionCategory.InvestPool ? (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex-1 px-4 pt-2.5">
          <WidgetList
            items={paginatedTransactions.payload.data}
            getKey={(item) => item.txHash}
            renderHeader={renderHeader}
            renderItem={(item) => renderItem(item as InvestPoolTx, configs)}
            renderEmpty={() => (
              <div className="mt-24 w-full flex justify-center gap-1 opacity-75">
                <InboxOutlined /> No transactions found
              </div>
            )}
          />
        </div>
        <div className="WidgetFooter flex justify-end">
          <WidgetPaginator
            page={page}
            pageSize={pageSizes[TreasuryTransactionCategory.InvestPool]}
            total={paginatedTransactions.payload.total || 0}
            onPageChange={setPage}
          />
        </div>
      </div>
      <TreasuryInvestPoolTransactionsFilters
        value={filters}
        onChange={setFilters}
      />
    </>
  ) : null;
}
