import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import { commodityTokenApi } from "../../../http/commodity-tokens.api";
import {
  AppPage,
  AppRefreshStatus,
  CommodityTokenConversionCommission,
} from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import { appActions } from "../../app/app.state";
import { useModal } from "../../../providers/ModalProvider";
import SetCommodityTokenConversionCommissionForm from "./SetCommodityTokenConversionCommissionForm";

export default function CommodityTokensSettings() {
  const [settings, setSettings] = useState<{
    conversionCommission: CommodityTokenConversionCommission;
  }>();
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const dispatch = useDispatch();
  const isGetSettingsPending = useMemo(
    () =>
      refreshPage == AppPage.CommodityTokensSettings &&
      refreshStatus == AppRefreshStatus.InProgress,
    [refreshStatus, refreshPage]
  );
  const { openModal } = useModal();

  const getSettings = async () => {
    try {
      dispatch(
        appActions.changeRefresh({
          status: AppRefreshStatus.InProgress,
          page: AppPage.CommodityTokensSettings,
        })
      );

      const conversionCommission =
        await commodityTokenApi.getConversionCommission();
      setSettings({ conversionCommission });
    } finally {
      dispatch(
        appActions.changeRefresh({
          status: AppRefreshStatus.Idle,
          page: AppPage.CommodityTokensSettings,
        })
      );
    }
  };

  const handleEditConversionCommission = async () => {
    if (
      await openModal((props) => (
        <SetCommodityTokenConversionCommissionForm {...props} />
      ))
    ) {
      getSettings();
    }
  };

  useEffectOnce(() => {
    getSettings();
  });

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.CommodityTokensSettings
    ) {
      getSettings();
    }
  }, [refreshStatus, refreshPage]);

  return (
    <div className="w-full mt-4">
      {isGetSettingsPending ? (
        <div className="mt-[calc(50vh-200px)] flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div className=" flex justify-start">
          <Card>
            <CardContent>
              <div className="mb-4 grid grid-cols-[100px_100px] gap-4 text-center text-sm opacity-60">
                <span className="w-20">Conversion Currency</span>
                <span className="w-20">Commission Rate</span>
              </div>
              {settings?.conversionCommission ? (
                <div className="grid grid-cols-[90px_100px] gap-4 text-center">
                  <h4 className="text-2xl">
                    {settings.conversionCommission.token.symbol}
                  </h4>
                  <span className="text-2xl">
                    {settings.conversionCommission.conversionCommission}%
                  </span>
                </div>
              ) : (
                <h4 className="text-2xl">N/A</h4>
              )}
            </CardContent>

            <CardActions className="flex justify-end">
              <Button
                size="small"
                color="info"
                onClick={handleEditConversionCommission}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        </div>
      )}
    </div>
  );
}
