import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import useBottomSheet from "../../../hooks/use-bottom-sheet";
import { AppPage, AppRefreshStatus } from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import SmartDealsTable from "../../companies/components/SmartDealsTable";
import {
  getSmartDealsAction,
  getSmartDealsStatusCountsAction,
} from "../blockchain.effects";
import SmartDealPage from "./SmartDealPage";
import SmartDealsFilters from "./SmartDealsFilters";
import { selectIsAuthenticated } from "../../auth/auth.selectors";

export default function SmartDeals() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const setBottomSheet = useBottomSheet();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSmartDeals = () => {
    dispatch(getSmartDealsStatusCountsAction({ changeRefresh: true }));
    dispatch(getSmartDealsAction({ page: 0 }));
  };

  const showSmartDealPage = (id) => {
    setBottomSheet({
      component: <SmartDealPage id={id} onClose={handleSmartDealPageClose} />,
      onClose: handleSmartDealPageClose,
    });
  };

  const handleSmartDealPageClose = () => {
    setBottomSheet(null);
    navigate(-1);
  };

  useEffectOnce(() => {
    dispatch(getSmartDealsStatusCountsAction({ changeRefresh: true }));
  });

  useEffect(() => {
    const { id } = params;
    id && isAuthenticated ? showSmartDealPage(id) : setBottomSheet(null);
  }, [params?.id, isAuthenticated]);

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.SmartDeals
    ) {
      getSmartDeals();
    }
  }, [refreshStatus, refreshPage]);

  return (
    <div className="w-constraint">
      <SmartDealsFilters />
      <SmartDealsTable />
    </div>
  );
}
