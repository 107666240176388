export const DEFAULTS = {
  RowsPerPageOptions: [15, 25, 100],
  PageSize: 15,
  NotificationsPageSize: 10,
};

export const CONSTANTS = {
  Api: {
    timeout: 60000,
  },
  DeletePermanently: "delete permanently",
  CroneValidation: {
    seconds: true,
  },
  CoreServiceUrl: process.env.REACT_APP_CORE_SERVICE_URL,
  ApiVersionPath: process.env.REACT_APP_API_VERSION_PATH,
  WsUrl: process.env.REACT_APP_WS_URL,
  FirebaseVapid: process.env.REACT_APP_FIREBASE_VAPID,
  Blockchain: {
    walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    isRealBlockchain: process.env.REACT_APP_BLOCKCHAIN_TYPE == 'real',
    paymentInvalidateTimeoutSec: 30,
  },
};
