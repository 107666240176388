import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import FileThumbnail from "../../../components/FileThumbnail/FileThumbnail";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import SmartDealDocumentStateChip from "../../../components/SmartDealDocumentStateChip/SmartDealDocumentStateChip";
import ModalProvider, { useModal } from "../../../providers/ModalProvider";
import BlockchainEventsModalProvider from "../providers/BlockchainEventsModalProvider";
import { SmartDealDocument as SmartDealDocumentType } from "../types";
import SmartDealDocument from "./SmartDealDocument";

interface IProps {
  documents: SmartDealDocumentType[];
  isLoading: boolean;
}

export default function SmartDealDocumentsTable({
  documents,
  isLoading,
}: IProps) {
  const [rows, setRows] = useState([]);
  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: "rowNo",
        headerName: "",
        sortable: false,
        width: 50,
      },
      {
        field: "title",
        headerName: "Title",
        flex: 1,
      },
      { field: "ownerType", headerName: "Added By", flex: 1 },
      {
        field: "url",
        headerName: "File",
        renderCell: ({ value }) => <FileThumbnail url={value} />,
        sortable: false,
        flex: 1,
      },
      {
        field: "state",
        headerName: "Status",
        renderCell: ({ value }) => <SmartDealDocumentStateChip state={value} />,
        flex: 1,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
        width: 120,
      },
    ],
    []
  );
  const [orderBy, setOrderBy] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);
  const { openModal } = useModal();

  const handleRowClick = (document: SmartDealDocumentType) => {
    openModal(
      (props) => (
        <ModalProvider>
          <BlockchainEventsModalProvider>
            <SmartDealDocument initialDocument={document} {...props} />
          </BlockchainEventsModalProvider>
        </ModalProvider>
      ),
      {
        hideCloseButton: false,
        closeOnClickOutside: true,
      }
    );
  };

  useEffect(() => {
    setRows(
      (documents?.map((d, index) => ({
        ...d,
        rowNo: index + 1,
      })) as any) || []
    );
  }, [documents]);

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 260px)" }}
        loading={isLoading}
        pagination
        paginationMode="client"
        autoPageSize
        rowCount={documents?.length}
        sortingMode="client"
        sortModel={orderBy}
        onSortModelChange={setOrderBy}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No documents" />,
        }}
        getRowClassName={(params) => "cursor-pointer"}
        onRowClick={({ row }) => handleRowClick(row)}
      />
    </div>
  );
}
