import { ExpandMore, FilterAltOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MouseEvent, useId, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { MenuOnClose, useMenu } from "../../../providers/MenuProvider";
import {
  CommodityToken,
  CommodityTokenTxAction,
  CommodityTokenTxActions,
} from "../../../types";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { isNullOrEmpty, removeNullOrEmpty } from "../../../utils/obj-utils";
import { selectCommodityTokens } from "../../commodity-tokens/commodity-tokens.selectors";
import { TreasuryInternalTransactionsFilters as TreasuryInternalTransactionsFiltersType } from "../types/treasury-internal-transactions-filters.type";

interface FiltersProps {
  preFilters?: TreasuryInternalTransactionsFiltersType;
  onClose: MenuOnClose<TreasuryInternalTransactionsFiltersType>;
}

const Filters = ({ preFilters, onClose }: FiltersProps) => {
  const [filters, setFilters] =
    useState<TreasuryInternalTransactionsFiltersType>(preFilters);
  const commodityTokens = useSelector(selectCommodityTokens);

  const patchFilters = (
    update: Partial<TreasuryInternalTransactionsFiltersType>
  ) => {
    setFilters((filters) =>
      removeNullOrEmpty({
        ...filters,
        ...update,
      })
    );
  };

  const hasFilter = (
    key: keyof TreasuryInternalTransactionsFiltersType
  ): boolean => {
    return filters?.[key] != null;
  };

  const isDirty = useMemo(
    () => JSON.stringify(preFilters || {}) != JSON.stringify(filters || {}),
    [preFilters, filters]
  );

  const errors = useMemo(() => {
    let errors: TreasuryInternalTransactionsFiltersType = {};
    if (filters?.account && !isValidBlockchainAddress(filters.account)) {
      errors["account"] = "Address is not valid";
    }

    return errors;
  }, [filters]);

  return (
    <div className="p-4 w-[380px] ">
      <div className="flex items-center justify-between mb-4">
        <Button
          color="info"
          variant="contained"
          size="small"
          disabled={!isDirty || !isNullOrEmpty(errors)}
          onClick={() => onClose(filters)}
        >
          Apply Changes
        </Button>
        <Button
          color="info"
          size="small"
          disabled={!Object.keys(filters || {}).length}
          onClick={() => setFilters({} as any)}
        >
          Clear Filters
        </Button>
      </div>

      <Accordion defaultExpanded={hasFilter("timeRange")}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Time Range
        </AccordionSummary>
        <AccordionDetails>
          <DateRangePicker
            value={filters?.timeRange}
            onChange={(e) => patchFilters({ timeRange: e })}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={hasFilter("type") || hasFilter("token")}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Type & Token
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex gap-4">
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                value={filters?.type || "All"}
                label="Type"
                onChange={(e) => {
                  const value = e.target.value;
                  patchFilters({
                    type:
                      value == "All" ? null : (value as CommodityTokenTxAction),
                  });
                }}
              >
                {["All", ...CommodityTokenTxActions].map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Token</InputLabel>
              <Select
                value={filters?.token || "All"}
                label="Token"
                onChange={(e) => {
                  const value = e.target.value;
                  patchFilters({
                    token: value == "All" ? null : value,
                  });
                }}
              >
                {["All", ...commodityTokens].map((token) => (
                  <MenuItem
                    key={
                      token == "All" ? token : (token as CommodityToken).symbol
                    }
                    value={
                      token == "All" ? token : (token as CommodityToken).address
                    }
                  >
                    {token == "All" ? token : (token as CommodityToken).symbol}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={hasFilter("account")}>
        <AccordionSummary expandIcon={<ExpandMore />}>From</AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder="Address starts with 0x..."
            value={filters?.account || ""}
            onChange={(e) => patchFilters({ account: e.target.value })}
            size="small"
            fullWidth
            error={!!errors?.account}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

interface IProps {
  value: TreasuryInternalTransactionsFiltersType;
  onChange: (filters: TreasuryInternalTransactionsFiltersType) => void;
}

export default function TreasuryInternalTransactionsFilters({
  value,
  onChange,
}: IProps) {
  const { openMenu } = useMenu();
  const menuTriggerId = useId();

  const handleClick = async (e: MouseEvent<HTMLElement>) => {
    const result = await openMenu<TreasuryInternalTransactionsFiltersType>(
      (props) => <Filters {...props} preFilters={value} />,
      e.currentTarget as HTMLElement,
      {
        className: "mr-2 bg-bg-1",
        placement: "bottom-start",
      }
    );

    result && onChange(result);
  };

  return (
    <Badge
      className="filter-badge absolute top-[10px] right-4"
      badgeContent={isNullOrEmpty(value) ? 0 : 1}
      variant="dot"
      color="secondary"
    >
      <IconButton id={menuTriggerId} size="small" onClick={handleClick}>
        <FilterAltOutlined className="text-[22px]" />
      </IconButton>
    </Badge>
  );
}
