import { Link } from "react-router-dom";
import { CloseModal } from "../../../providers/ModalProvider";
import { SmartDealPromptPayStatus } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import { InvestPoolsFiltersTab } from "../../invest/components/InvestPoolsFilters";
import { SmartDeal } from "../types";

interface IProps {
  smartDeal: SmartDeal;
  close: CloseModal<void>;
}

export default function SmartDealPromptPayResultReview({
  smartDeal,
  close,
}: IProps) {
  return (
    <div className="max-w-full p-4" style={{ width: "420px" }}>
      <header className="modal-header flex justify-between items-center pr-4">
        Prompt Pay{" "}
        {smartDeal.promptPay.status == SmartDealPromptPayStatus.Approved
          ? "Activated"
          : "Rejected"}
      </header>

      <section className="smart-deal-prompt-pay-info-section">
        {smartDeal.promptPay.status == SmartDealPromptPayStatus.Approved && (
          <div className="info-section-item">
            <span>Prompt Pay Pool</span>
            <span className="info-section-item__value link">
              <Link
                to={`/investment-pools/${InvestPoolsFiltersTab.PromptPayPools}/${smartDeal.promptPay.investPool.id}`}
              >
                {smartDeal.promptPay.investPool.name}
              </Link>
            </span>
          </div>
        )}

        {smartDeal.promptPay.status == SmartDealPromptPayStatus.Approved && (
          <div className="info-section-item">
            <span>Allocated Amount</span>
            <span className="info-section-item__value">
              {formatNumberWithSeparator(
                smartDeal.promptPay.allocatedAmount.amount
              )}{" "}
              {smartDeal.promptPay.allocatedAmount.tokenSymbol}
            </span>
          </div>
        )}

        <div className="info-section-item">
          <span>Remarks</span>
          <span className="info-section-item__value">
            {smartDeal.promptPay.remarks}
          </span>
        </div>
      </section>

      <footer className="modal-footer flex justify-end gap-3"></footer>
    </div>
  );
}
