import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import { TimeWindow } from "../../../types";
import { getTimeWindowText } from "../../../utils/coimex-utils";
import { getOpportunitiesStatisticsAction } from "../dashboard.effects";
import {
  selectIsGetOpportunitiesStatisticsPending,
  selectOpportunitiesStatistics,
  selectOpportunitiesWindow,
} from "../dashboard.selectors";
import WidgetBarChart from "./WidgetBarChart";
import WidgetSelect from "./WidgetSelect";

const windows: TimeWindow[] = [30, 90];

export default function OffersAndRequests() {
  const statistics = useSelector(selectOpportunitiesStatistics);
  const isPending = useSelector(selectIsGetOpportunitiesStatisticsPending);
  const selectedWindow = useSelector(selectOpportunitiesWindow);
  const dispatch = useDispatch();

  const handleSelectedWindowChange = (window: TimeWindow) => {
    dispatch(getOpportunitiesStatisticsAction({ days: window as number }));
  };

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full p-widget flex flex-col">
        <div className="WidgetHeader mb-4 flex items-center gap-2">
          Offers and Requests in
          <WidgetSelect
            className="font-medium"
            items={windows.map((w) => ({
              label: getTimeWindowText(w),
              value: w,
            }))}
            selectedValue={selectedWindow}
            onValueChange={handleSelectedWindowChange}
          />
        </div>
        <div className="flex-1 flex flex-col gap-6">
          <WidgetBarChart
            className="flex-1 border-b border-solid"
            title="Offers per day"
            data={statistics?.offers?.series || []}
            total={statistics?.offers?.total || 0}
          />
          <WidgetBarChart
            className="flex-1"
            title="Requests per day"
            data={statistics?.requests?.series || []}
            total={statistics?.requests?.total || 0}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
}
