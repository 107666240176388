import { combineReducers } from "@reduxjs/toolkit";
import { APP_STATE_NAME, appReducer } from "../app/app.state";
import { AUTH_STATE_NAME, authReducer } from "../auth/auth.state";
import { BLOCKCHAIN_STATE_NAME, blockchainReducer } from "../blockchain/blockchain.state";
import { COMMODITY_TOKENS_STATE_NAME, commodityTokensReducer } from "../commodity-tokens/commodity-tokens.state";
import { COMPANIES_STATE_NAME, companiesReducer } from "../companies/companies.state";
import { DASHBOARD_STATE_NAME, dashboardReducer } from "../dashboard/dashboard.state";
import { INVEST_STATE_NAME, investReducer } from "../invest/invest.state";
import { NOTIFICATIONS_STATE_NAME, notificationsReducer } from "../notifications/notifications.state";
import { OFFERS_STATE_NAME, offersReducer } from "../offers/offers.state";
import { PAYMENTS_STATE_NAME, paymentsReducer } from "../payments/payments.state";
import { PLANS_STATE_NAME, plansReducer } from "../plans/plans.state";
import { REQUESTS_STATE_NAME, requestsReducer } from "../requests/requests.state";
import { SCHEDULES_STATE_NAME, schedulesReducer } from "../schedules/schedules.state";
import { SETTING_STATE_NAME, settingsReducer } from "../settings/settings.state";
import { TREASURY_STATE_NAME, treasuryReducer } from "../treasury/treasury.state";

export const rootReducer = combineReducers({
    [APP_STATE_NAME]: appReducer,
    [DASHBOARD_STATE_NAME]: dashboardReducer,
    [AUTH_STATE_NAME]: authReducer,
    [COMPANIES_STATE_NAME]: companiesReducer,
    [SETTING_STATE_NAME]: settingsReducer,
    [PAYMENTS_STATE_NAME]: paymentsReducer,
    [OFFERS_STATE_NAME]: offersReducer,
    [BLOCKCHAIN_STATE_NAME]: blockchainReducer,
    [REQUESTS_STATE_NAME]: requestsReducer,
    [PLANS_STATE_NAME]: plansReducer,
    [SCHEDULES_STATE_NAME]: schedulesReducer,
    [NOTIFICATIONS_STATE_NAME]: notificationsReducer,
    [COMMODITY_TOKENS_STATE_NAME]: commodityTokensReducer,
    [TREASURY_STATE_NAME]: treasuryReducer,
    [INVEST_STATE_NAME]: investReducer
});

export type RootState = ReturnType<typeof rootReducer>;