import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { useAccount, useConnect, useDisconnect, WagmiProvider } from "wagmi";
import wagmiConfig from "../common/wagmi-config";
import WalletConnector from "../components/WalletConnector/WalletConnector";
import { useMainLayoutHeaderSlot } from "../layouts/MainLayout/providers/MainLayouHeaderSlotProvider";

const queryClient = new QueryClient();

function WalletConnectorProvider({ children }) {
  const {
    isConnected,
    isConnecting,
    isReconnecting,
    address,
    connector,
    chain,
  } = useAccount();
  const { connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const { setToolbarSlot } = useMainLayoutHeaderSlot();

  useEffect(() => {
    setToolbarSlot(
      <WalletConnector
        isConnected={isConnected}
        isConnecting={isConnecting}
        isReconnecting={isReconnecting}
        address={address}
        connectors={connectors}
        connector={connector}
        disconnect={disconnect}
        chain={chain}
      />
    );

    return () => {
      setToolbarSlot(null);
    };
  }, [isConnected, isConnecting, isReconnecting, address]);

  return <>{children}</>;
}

export default ({ children }) => (
  <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <WalletConnectorProvider>{children}</WalletConnectorProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
