import { ComponentProps, createContext, useCallback, useContext } from "react";
import { useModal } from "../../../providers/ModalProvider";
import TokenTransferForm from "../components/TokenTransferForm";

interface ContextProps {
  openTransferModal: (
    props: Omit<ComponentProps<typeof TokenTransferForm>, "close">
  ) => Promise<boolean>;
}

const TokenTransferModalContext = createContext<ContextProps>(null);

export default function TokenTransferModalProvider({ children }) {
  const { openModal } = useModal();

  const openTransferModal = useCallback<ContextProps["openTransferModal"]>(
    (componentProps) => {
      return openModal(
        (props) => <TokenTransferForm {...props} {...componentProps} />,
        { closeOnClickOutside: false, hideCloseButton: true }
      );
    },
    []
  );

  return (
    <TokenTransferModalContext.Provider value={{ openTransferModal }}>
      {children}
    </TokenTransferModalContext.Provider>
  );
}

export const useTokenTransferModal = () =>
  useContext(TokenTransferModalContext);
