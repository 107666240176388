import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import Tabs from "../../../components/Tabs/Tabs";
import { TimeWindow } from "../../../types";
import { getTimeWindowText } from "../../../utils/coimex-utils";
import { getCurrentPrecedingStatisticsAction } from "../dashboard.effects";
import {
  selectCurrentPrecedingStatistics,
  selectCurrentPrecedingWindow,
  selectIsGetCurrentPrecedingStatisticsPending,
} from "../dashboard.selectors";
import { CurrentPrecedingStatistic } from "../types/current-preceding-statistic.type";
import CurrentPrecedingChart from "./CurrentPrecedingChart";
import WidgetSelect from "./WidgetSelect";

const renderTab = (tab: CurrentPrecedingStatistic) => (
  <div className="w-28 flex flex-col gap-1 text-xs">
    <span>{tab.label}</span>
    <span className="text-base">{tab.total}</span>
    <span
      className={classNames(
        "flex items-center justify-center",
        tab.change > 0 ? "text-green-600" : tab.change < 0 ? "text-red-600" : ""
      )}
    >
      {tab.change > 0 ? (
        <ArrowUpward className="text-sm -mt-0.5" />
      ) : tab.change < 0 ? (
        <ArrowDownward className="text-sm" />
      ) : (
        <></>
      )}
      {Math.abs(tab.change)}%
    </span>
  </div>
);

const windows: TimeWindow[] = [30, 90];

export default function CurrentPreceding() {
  const statistics = useSelector(selectCurrentPrecedingStatistics);
  const selectedWindow = useSelector(selectCurrentPrecedingWindow);
  const [selectedTab, setSelectedTab] = useState(0);
  const isPending = useSelector(selectIsGetCurrentPrecedingStatisticsPending);
  const dispatch = useDispatch();

  const handleSelectedWindowChange = (window: TimeWindow) => {
    dispatch(getCurrentPrecedingStatisticsAction({ days: window as number }));
  };

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full flex flex-col">
        <div className="flex-1 flex flex-col">
          <Tabs
            selectedTab={selectedTab}
            items={statistics}
            renderItem={renderTab}
            onTabChange={setSelectedTab}
          />
          <CurrentPrecedingChart
            className="flex-1"
            current={statistics?.[selectedTab]?.current || []}
            preceding={statistics?.[selectedTab]?.preceding || []}
            window={selectedWindow}
          />
        </div>
        <div className="WidgetFooter flex items-center">
          <WidgetSelect
            items={windows.map((w) => ({
              label: getTimeWindowText(w),
              value: w,
            }))}
            selectedValue={selectedWindow}
            onValueChange={handleSelectedWindowChange}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
}
