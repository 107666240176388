import { Business, DeleteForeverOutlined, MoreVert } from "@mui/icons-material";
import { Avatar, Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AvatarsOverlap from "../../../components/AvatarsOverlap/AvatarsOverlap";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import OpportunityStateChip from "../../../components/OpportunityStateChip/OpportunityStateChip";
import useDeleteConfirm from "../../../hooks/use-delete-confirm";
import { opportunitiesApi } from "../../../http";
import { Opportunity, Pagination } from "../../../types";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import { appActions } from "../../app/app.state";
import { OpportunityStatusFilter } from "../../offers/types/opportunity-status-filter.type";
import {
  selectIsGetRequestsPending,
  selectRequests,
  selectRequestsOrderByFilter,
  selectRequestsPagination,
  selectRequestsStatusFilter,
} from "../requests.selectors";
import { getRequestsAction } from "../requets.effects";

export default function RequestsTable() {
  const requests = useSelector(selectRequests);
  const pagination = useSelector(selectRequestsPagination);
  const isGetRequestsPending = useSelector(selectIsGetRequestsPending);
  const orderBy = useSelector(selectRequestsOrderByFilter);
  const status = useSelector(selectRequestsStatusFilter);
  const [rows, setRows] = useState([]);
  const [selectedMenuRequest, setSelectedMenuRequest] =
    useState<Opportunity | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const confirmDelete = useDeleteConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePagination = (
    pg: Partial<Pick<Pagination, "page" | "pageSize">>
  ) => {
    dispatch(getRequestsAction(pg));
  };

  const handleSorting = (sort: GridSortModel) => {
    dispatch(
      getRequestsAction({
        filters: { orderBy: sort?.[0] || { field: "createdAt", sort: "desc" } },
      })
    );
  };

  const handleRowClick = (request: Opportunity) => {
    navigate(request.id);
  };

  const handleDeleteRequest = async () => {
    closeMenu();
    if (
      await confirmDelete({
        title: selectedMenuRequest!.productName,
        onConfirm: () =>
          opportunitiesApi.deleteOpportunity(selectedMenuRequest!.id),
      })
    ) {
      enqueueSnackbar("Offer deleted successfully.", { variant: "info" });
      dispatch(
        appActions.changeRefresh({
          page: AppPage.Requests,
          status: AppRefreshStatus.Invalidated,
        })
      );
    }
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    request: Opportunity
  ) => {
    e.stopPropagation();
    setSelectedMenuRequest(request);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    setRows(
      requests?.map((request, index) => ({
        ...request,
        rowNo: pagination.page * pagination.pageSize + index + 1,
      })) as any
    );
  }, [requests]);

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: "rowNo",
        headerName: "",
        width: 50,
        sortable: false,
      },
      {
        field: "productName",
        headerName: "Product Name",
        flex: 1,
      },
      {
        field: "company",
        headerName: "Company",
        renderCell: (params) => (
          <Link
            className="link flex items-center gap-x-2"
            to={`/companies/${params.value.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            <Avatar src={params.value.avatarUrl} sx={{ width: 28, height: 28 }}>
              <Business fontSize="small" />
            </Avatar>
            <span>{params.value.name}</span>
          </Link>
        ),
        flex: 1,
      },
      {
        field: "subCategory",
        headerName: "Category",
        renderCell: (params) => <span>{params.value.name}</span>,
        flex: 1,
      },
      {
        field: "state",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => (
          <OpportunityStateChip
            status={params.value}
            remarks={params.row.remarks}
          />
        ),
      },
      {
        field: "productPhotos",
        headerName: "Product Photos",
        renderCell: (params) => (
          <AvatarsOverlap
            urls={params.value}
            max={2}
            style={{ minWidth: "120px" }}
          />
        ),
        flex: 1,
        sortable: false,
      },
      {
        field: "publishedAt",
        headerName: "Published",
        valueFormatter: (params) =>
          params.value ? moment(params.value).fromNow() : "",
        flex: 1,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
        width: 120,
      },
      {
        field: "",
        renderCell: (params) => (
          <IconButton onClick={(e) => showMenu(e, params.row)}>
            <MoreVert />
          </IconButton>
        ),
        width: 50,
        align: "right",
        sortable: false,
      },
    ],
    []
  );

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 200px)" }}
        loading={isGetRequestsPending}
        pagination
        paginationMode="server"
        autoPageSize
        rowCount={pagination.total}
        page={pagination.page}
        sortingMode="server"
        sortModel={orderBy ? [orderBy] : []}
        onSortModelChange={handleSorting}
        onPageChange={(page) => handlePagination({ page })}
        onPageSizeChange={(pageSize) => handlePagination({ pageSize })}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No requests" />,
        }}
        columnVisibilityModel={{
          publishedAt: status === OpportunityStatusFilter.Active,
        }}
        getRowClassName={(params) => "cursor-pointer"}
        onRowClick={({ row }) => handleRowClick(row)}
      />
      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <Button
          color="error"
          variant="text"
          startIcon={<DeleteForeverOutlined />}
          onClick={handleDeleteRequest}
        >
          Delete
        </Button>
      </MoreMenu>
    </div>
  );
}
