import { cookieStorage, createConfig, createStorage, http } from "wagmi";
import { avalanche, avalancheFuji } from "wagmi/chains";
import { injected, walletConnect } from "wagmi/connectors";
import { CONSTANTS } from "./constants";

export const projectId = CONSTANTS.Blockchain.walletConnectProjectId;
const chain = CONSTANTS.Blockchain.isRealBlockchain ? avalanche : avalancheFuji;

// @ts-ignore
export default createConfig({
  chains: [chain],
  connectors: [injected(), walletConnect({ projectId })],
  transports: {
    [chain.id]: http(),
  } as any,
  ssr: false,
  storage: createStorage({
    storage: cookieStorage,
  }),
});

export interface WalletConnector {
  name: string
  icon: string
}

export enum WalletConnectorId {
  Metamask = 'io.metamask',
  Injected = "injected",
  WalletConnect = "walletConnect"
}

export const WalletConnectors: Record<WalletConnectorId, WalletConnector> = {
  [WalletConnectorId.Metamask]: {
    name: "MetaMask",
    icon: '/assets/images/metamask-icon.svg'
  },
  [WalletConnectorId.Injected]: {
    name: "MetaMask",
    icon: '/assets/images/metamask-icon.svg'
  },
  [WalletConnectorId.WalletConnect]: {
    name: "WalletConnect",
    icon: "/assets/images/walletconnect-icon.svg"
  }
}
