export function formatNumberCompact(
  n: number,
  options?: Partial<{
    maximumFractionDigits: number;
    minimumFractionDigits: number;
  }>
): string {
  const { maximumFractionDigits, minimumFractionDigits } = options || {};
  return n != null
    ? new Intl.NumberFormat("en-US", {
        maximumFractionDigits,
        minimumFractionDigits,
        notation: "compact",
        compactDisplay: "short",
        roundingMode: "halfEven",
      } as Intl.NumberFormatOptions).format(n)
    : "";
}

export function formatNumberWithSeparator(
  number: number | string,
  options?: Partial<{
    thousandSeparator: string;
    minimumFractionDigits: number;
    maximumFractionDigits?: number;
  }>
): string {
  number = Number(number);
  const { thousandSeparator, minimumFractionDigits, maximumFractionDigits } = {
    thousandSeparator: options?.thousandSeparator ?? ",",
    minimumFractionDigits: options?.minimumFractionDigits ?? 0,
    maximumFractionDigits: options?.maximumFractionDigits,
  };
  const decimalSeparator: string = thousandSeparator === "," ? "." : ",";
  const numberString: string =
    maximumFractionDigits !== undefined
      ? number.toFixed(maximumFractionDigits)
      : number.toString();
  const parts: string[] = numberString.split(".");
  const integerPartWithSeparator: string =
    (number < 0 ? "-" : "") +
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  const decimalPart: string =
    parts[1]?.padEnd(minimumFractionDigits, "0") ?? "";
  return decimalPart
    ? `${integerPartWithSeparator}${decimalSeparator}${decimalPart}`
    : integerPartWithSeparator;
}
