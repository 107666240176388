import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSearchHitDecoratedProps } from "../../../common/decorators/search-hit.decorator";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import {
  selectCompaniesCountryDistribution,
  selectIsGetCompaniesCountriesDistributionPending,
} from "../dashboard.selectors";
import { CompaniesCountryDistributionStatistics } from "../types/companies-country-distribution-statistics.type";
import WidgetList from "./WidgetList";
import WidgetPaginator from "./WidgetPaginator";
import WidgetSearch from "./WidgetSearch";

const renderHeader = () => (
  <div className="flex justify-between items-center">
    <span>Name</span>
    <span>Number of Companies</span>
  </div>
);

const renderItem = (item: CompaniesCountryDistributionStatistics) => {
  return (
    <div className="relative flex justify-between items-center text-xs">
      <span className="text-sm">{item.name}</span>
      <span>{item.count}</span>
      <div
        className="absolute left-0 -bottom-1 h-1 rounded-md bg-secondary"
        style={{ right: `${100 - item.percent}%` }}
      ></div>
    </div>
  );
};

export default function CompaniesCountryDistributionList() {
  const countries = useSelector(selectCompaniesCountryDistribution);
  const [filteredCountries, setFilteredCountries] = useState<
    CompaniesCountryDistributionStatistics[]
  >([]);
  const [paginatedCountries, setPaginatedCountries] = useState<
    CompaniesCountryDistributionStatistics[]
  >([]);
  const isPending = useSelector(
    selectIsGetCompaniesCountriesDistributionPending
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const total = countries.sumOf((c) => c.companiesCount);
    let filtered = countries.map(
      (c) =>
        ({
          id: c.country.id,
          name: c.country.name,
          count: c.companiesCount,
          percent: Math.ceil((c.companiesCount * 100) / (total || 1)),
        } as CompaniesCountryDistributionStatistics)
    );
    if (search) {
      filtered = filtered.search(
        search,
        ...getSearchHitDecoratedProps(CompaniesCountryDistributionStatistics)
      );
    }
    setFilteredCountries(filtered);
  }, [countries, search]);

  useEffect(() => {
    setPaginatedCountries(
      filteredCountries.slice(page * pageSize, (page + 1) * pageSize)
    );
  }, [filteredCountries, page, pageSize]);

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full flex flex-col">
        <div className="flex-1 p-widget">
          <WidgetSearch
            title="Companies Country Distribution"
            onSearch={setSearch}
          />
          <div className="flex-1 mt-4">
            <WidgetList
              items={paginatedCountries}
              renderHeader={renderHeader}
              renderItem={renderItem}
            />
          </div>
        </div>
        <div className="WidgetFooter flex justify-end">
          <WidgetPaginator
            page={page}
            pageSize={pageSize}
            total={filteredCountries.length}
            onPageChange={setPage}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
}
