import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import EstimateGasFeeTooltip from "../../../components/EstimateGasFeeTooltip/EstimateGasFeeTooltip";
import { treasuryApi } from "../../../http";
import { Erc20TokenInfo } from "../../../types";
import {
  isValidBlockchainAddress,
  toDecimalsAmount,
} from "../../../utils/blockchain-utils";
import { RwaContractBalances } from "../types/rwa-contract-balances.type";
import {
  UpdateRwaBalanceType,
  UpdateRwaBalanceTypes,
} from "../types/update-rwa-balance-type.type";

interface IProps {
  rwaContractBalances: RwaContractBalances;
  commodityExchangeTokens: Erc20TokenInfo[];
  close?: (modified?: boolean) => void;
}

interface FormProps {
  rwaTokenAddress: string;
  type: UpdateRwaBalanceType;
  tokenAddress: string;
  newBalance: number;
}

export default function RWATokenUpdateBalanceForm({
  rwaContractBalances,
  commodityExchangeTokens,
  close,
}: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationSchema = Yup.object().shape({
    rwaTokenAddress: Yup.string()
      .required()
      .test("valid", function (value) {
        return isValidBlockchainAddress(value);
      }),
    tokenAddress: Yup.string()
      .required()
      .test("valid", function (value) {
        return isValidBlockchainAddress(value);
      }),
    type: Yup.string().required(),
    newBalance: Yup.number().required().min(0),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      rwaTokenAddress: "",
      type: UpdateRwaBalanceType.Treasury,
      tokenAddress: "",
      newBalance: null,
    },
  });
  const rwaTokenAddress = form.watch("rwaTokenAddress");
  const rwaToken = rwaContractBalances.rwaBalances.find(
    (rwa) => rwa.rwaToken.address == rwaTokenAddress
  );
  const tokenAddress = form.watch("tokenAddress");
  const type = form.watch("type");
  const token = commodityExchangeTokens.find((t) => t.address == tokenAddress);
  const newBalance = form.watch("newBalance");
  const oldBalance =
    rwaToken?.[token?.symbol]?.[
      type == UpdateRwaBalanceType.Treasury
        ? "treasuryFormatted"
        : "commissionFormatted"
    ];

  const onSubmit = async ({
    rwaTokenAddress,
    type,
    tokenAddress,
    newBalance,
  }: FormProps) => {
    try {
      setIsSubmitting(true);
      await treasuryApi.updateRwaContractBalance({
        rwaTokenAddress,
        type,
        tokenAddress,
        balanceChange: newBalance - oldBalance,
      });
      close(true);
    } catch {
      setIsSubmitting(false);
    }
  };

  const patchFormValue = (key, value) => {
    form.setValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const renderChange = () => {
    const change = newBalance - oldBalance;

    return (
      <div className="mt-2 ml-3.5 flex flex-col text-[13px] opacity-70">
        <div className="grid grid-cols-[120px_1fr]">
          Old balance:
          <span>
            {oldBalance} {token.symbol}
          </span>
        </div>

        <div className="grid grid-cols-[120px_1fr]">
          <span>Change:</span>
          <span>
            {change > 0 ? "+" : change < 0 ? "-" : ""}
            {isNaN(newBalance) ? "" : (newBalance - oldBalance).toFixed(3)}{" "}
            {token.symbol}
          </span>
        </div>
      </div>
    );
  };

  const getEstimateGasFeeArgs = () => {
    const change = newBalance - oldBalance;

    return [
      rwaTokenAddress,
      tokenAddress,
      BigInt(
        change < 0 ? toDecimalsAmount(Math.abs(change), token.decimals) : 0
      ).toString(),
      token.address,
      BigInt(
        change > 0 ? BigInt(toDecimalsAmount(change, token.decimals)) : 0
      ).toString(),
    ];
  };

  useEffect(() => {
    if (oldBalance != null) {
      patchFormValue("newBalance", oldBalance);
    }
  }, [oldBalance]);

  return (
    <form className="p-4 w-[380px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        Update Commodity Token Balance
      </header>

      <div className="flex flex-col gap-6">
        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Commodity Token</InputLabel>
          <Select
            label="Commodity Token"
            {...form.register("rwaTokenAddress")}
            defaultValue={""}
            error={!!form.formState.errors?.rwaTokenAddress}
          >
            {rwaContractBalances.rwaBalances.map((rwa) => (
              <MenuItem key={rwa.rwaToken.symbol} value={rwa.rwaToken.address}>
                {rwa.rwaToken.name} ({rwa.rwaToken.symbol})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>What to Update?</InputLabel>
          <Select
            label="What to Update?"
            {...form.register("type")}
            defaultValue={UpdateRwaBalanceType.Treasury}
            error={!!form.formState.errors?.type}
          >
            {UpdateRwaBalanceTypes.map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small" variant="filled" required>
          <InputLabel>Which Token?</InputLabel>
          <Select
            label="Which Token?"
            {...form.register("tokenAddress")}
            defaultValue={""}
            error={!!form.formState.errors?.tokenAddress}
          >
            {commodityExchangeTokens.map((t) => (
              <MenuItem key={t.symbol} value={t.address}>
                {t.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <TextField
            className="w-full"
            {...form.register("newBalance")}
            inputMode="decimal"
            label="New Balance"
            variant="filled"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{token?.symbol}</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: newBalance != null }}
            error={!!form.formState.errors?.newBalance}
            required
          />

          {rwaToken && token && renderChange()}
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <EstimateGasFeeTooltip
          method={
            type == UpdateRwaBalanceType.Treasury
              ? "convertTreasury"
              : "convertCommission"
          }
          args={getEstimateGasFeeArgs}
          disabled={!form.formState.isValid}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </EstimateGasFeeTooltip>

        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
